<template>
  <div>
    <cabeca></cabeca>
        

  <v-container grid-list-xs>
  <v-card>
    <v-card-title class="blue white--text text-h5">
      MANUAL
    </v-card-title>
    
    <v-row
      class="pa-4"
      justify="space-between"
    >
      <v-col cols="5">
        <v-treeview
          :active.sync="active"
          :items="$store.state.folder"
          :load-children="fetch"
          :open.sync="open"
          activatable
          color="warning"
          
          open-on-click
          transition
        >
        <template v-slot:prepend="{ item }">
          <v-icon
            v-if="item.children"
            v-text="`mdi-${item.id === 1 ? 'folder' : 'folder'}`" color="#FFD700"
          ></v-icon>
          <v-icon v-if="!item.children" v-text="`mdi-${item.id ? 'file' : 'file-remove'}`" color="grey" >
              
            </v-icon>
            
        </template>
        
        
        </v-treeview>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col
        class="d-flex text-center"
      >
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="text-h6 grey--text text--lighten-1 font-weight-light"
            style="align-self: center;"
          >
            Select a Manual
          </div>
          <v-card
            v-else
            :key="selected.id"
            class="pt-6 mx-auto"
            flat
            max-width="400"
          >
            <v-card-text>
              
              <h3 class="text-h5 mb-2">
                {{ selected.fleet }}
              </h3>
              <div class="blue--text mb-2">
                {{ selected.type_manual }}
              </div>
              <div class="blue--text subheading font-weight-bold">
                {{ selected.manual.description }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-row
              class="text-left"
              tag="v-card-text"
            >
              <v-col
                class="text-right mr-4 mb-2"
                tag="strong"
                cols="5"
              >
                Desc.:
              </v-col>
              <v-col>{{ selected.description }}</v-col>
              
              <v-col
                class="text-right mr-4 mb-2"
                tag="strong"
                cols="5"
              >
                Date:
              </v-col>
              <v-col>{{ selected.date }}</v-col>
              <v-col
                class="text-right mr-4 mb-2"
                tag="strong"
                cols="5"
              >
                File:
              </v-col>
              <v-col>
                
                  <v-btn  :disabled="(selected.file == null || selected.file == 'null')?true:false" color="success" small icon target="_blank" link :href="$store.state.path2 + selected.file"><v-icon>mdi-file-cad</v-icon></v-btn>
                
                
              </v-col>
            </v-row>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-card-actions>
      

      <v-spacer></v-spacer>

      <v-btn
        class="white--text"
        color="info darken-1"
        depressed  small route to="/newDocumments"
        :disabled=$store.state.permission
      >
        Add New Manual
        <v-icon right>
          mdi-book
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</v-container>

</div>

</template>

<script>
import cabeca from "@/components/cabeca";
  export default {
    components: {
    cabeca,
    
  },
    data: () => ({
      documments: [],
      isLoading: false,
      tree: [],
      types: [],
      active: [],
      avatar: null,
      open: [],
    }),

    computed: {
       selected () {
        if (!this.active.length) return undefined

        const file = this.active[0]
        if(file)
          return window.open(this.$store.state.path2+file,"_blank")
          return "/"  
        
      },
      items () {
        const children = this.types.map(type => ({
          id: type,
          name: this.getName(type),
          children: this.get(),
        }))

        return [{
          id: 1,
          name: 'All documments',
          children,
        }]
      },
      shouldShowTree () {
        return this.documments.length > 0 && !this.isLoading
      },
    },

    watch: {
      documments (val) {
        this.types = val.reduce((acc, cur) => {
          const type = cur.type_manual

          if (!acc.includes(type)) acc.push(type)

          return acc
        }, []).sort()
      },
    },

    methods: {
      selectDocumment(val){
        this.$store.dispatch('showDocumments',val);
      },
      
      fetch () {
        if (this.documments.length) return

        return this.documments = this.$store.state.folder
          
      },
      get () {
        const documments = []

        for (const brewery of this.documments) {
          

          documments.push({
            ...brewery,
            
          })
        }},
      getChildren (type) {
        const documments = []

        for (const brewery of this.documments) {
          if (brewery.type_manual === type) continue

          documments.push({
            ...brewery,
            name: this.getName(brewery.fleet),
            children:this.getChildren_(brewery.fleet),
          })
        }

        return documments.sort((a, b) => {
          return a.name > b.name ? 1 : -1
        })
      },
      getChildren_ (type) {
        const documments = []

        for (const brewery of this.documments) {
          if (brewery.fleet === type) continue

          documments.push({
            ...brewery,
            name: this.getName(brewery.manual),
            children:this.getChildren__(brewery),
          })
        }

        return documments.sort((a, b) => {
          return a.name > b.name ? 1 : -1
        })
      },
      getChildren__ (type) {
        const documments = []

        for (const brewery of this.documments) {
          if (brewery.manual === type) continue

          documments.push({
            ...brewery,
            name: this.getName(brewery.description),
          })
        }

        return documments.sort((a, b) => {
          return a.name > b.name ? 1 : -1
        })
      },
      
      getName (name) {
        return `${name.charAt(0).toUpperCase()}${name.slice(1)}`
      },
    },
    mounted(){
    
      this.$store.dispatch('permission');
      this.$store.dispatch('getFolder');
      
       if(this.$store.state.editing){

        this.dados = this.$store.state.editingItem;
        this.edit = true;

      }
  }
  }
</script>