<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" >

        <v-card-title class="px-0" primary-title>
            
        </v-card-title>
        <v-row class="justify-space-between">
            <h3>TECHINICAL LIBRARY</h3>
          <v-btn  small route to="/documments" color="info" class="elevation-0 ma-0 mb-2"> <v-icon class="mr-3">mdi-clipboard-list</v-icon> Manual List</v-btn>
        </v-row>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>


        <v-row>

                <v-col cols="4">
                    <v-autocomplete label="MANUAL TYPE" dense v-model="dados.type_manual" outlined :items="$store.state.manualType" item-value="description" item-text="description">
                      <template v-slot:item="{ item }" >
                        <v-list-item-content @click="manualRequest(item.id)">
                            {{item.description}}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
                </v-col>
                <v-col cols="4">
                    <v-autocomplete label="FLEET" v-model="dados.fleet" dense outlined :items="$store.state.fleetRequest" item-text="designation"
                    item-value="designation">
                    <template v-slot:item="{ item }" >
                        <v-list-item-content>
                            {{item.designation}}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
               </v-col>

               <v-col cols="4">
                    <v-autocomplete label="Manual" v-model="dados.manual_id" dense outlined :items="$store.state.manualRequest" item-text="description"
                    item-value="id">
                    <template v-slot:item="{ item }" >
                        <v-list-item-content>
                            {{item.description}}
                        </v-list-item-content>
                      </template>
                </v-autocomplete>
               </v-col>
               <v-col :cols="6">
                      <v-text-field
                            label="Description"
                            dense
                            outlined
                            v-model="dados.description"
                        ></v-text-field>
                </v-col>
                
              

              <v-col :cols="6">
                  <v-text-field
                  label="Date"
                  type="date"
                  dense
                  outlined
                  v-model="dados.date"
              ></v-text-field>
                </v-col>
                
                <v-col cols="6">
                    <v-file-input
                        dense outlined
                        label="File"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        v-model="file"
                    ></v-file-input>
                </v-col>
                
                
                 
                 
               


               <v-row class="justify-end">
                    <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                    <v-btn color="primary" @click="save" :disabled=$store.state.permission elevation="0" class="ml-2">Save</v-btn>
                </v-row>
               

           
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{

          dueDate:false,
          mel:false,
          thisLogIsForMaintenance:'',
          edit:false,
          file:'',
          dados:{
                fleet:'',
                manual_type:'',
                manual_id:'',
                date:'',
                description:''
                
                }
      }
  },
  methods:{
      clear(){
          this.dados={
                fleet:'',
                manual_type:'',
                manual:'',
                date:'',
                description:''
               
                }
      },
      
      fleetRequest(val){
      this.$store.dispatch("getFleetRequest",val);
    },
    manualRequest(val){
      
      this.$store.dispatch("getFleetRequest",val);
      this.$store.dispatch("getManualRequest",val);
    },
    // Funcao para salver users na bd
    save() {


        let store = this.$store;
      store.dispatch("loading")
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "documments";
      let dis = this;

      url += this.edit ? ("/"+this.dados.id.toString()) + "?_method=PATCH" : "";

       const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization':'Bearer '+window.sessionStorage.getItem("token")
            }
        }

        var form_data = new FormData();

        for ( var key in this.dados ) {
            form_data.append(key, this.dados[key]||'');
        }
        form_data.append('file',this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating Documments",
              "error"
            )
          else {
            if (!dis.edit) {
              store.dispatch('getDocumments');
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New Documments Updated successfuly. "
                  : "PARTS added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New PARTS edited successfuly. "
                  : "PARTS Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
  mounted(){
    this.$store.dispatch('getManualType');
      this.$store.dispatch('getAcType');
      this.$store.dispatch('getPartType');
      this.$store.dispatch('permission');
      this.$store.dispatch('getFolder');
       if(this.$store.state.editing){

        this.dados = this.$store.state.editingItem;
        this.edit = true;

      }
  }
  

}
</script>

<style>

</style>