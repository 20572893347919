<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" min-height="450">

        <v-card-title class="px-0" primary-title>
            
            <v-row>
                <p class="pr-2">AIRCRAFT MAINTENANCE CHECK PACKAGE</p>
                <v-spacer></v-spacer>
                <p class="subtitle-1">Reg No: {{reg_no}}</p>
                <v-spacer></v-spacer>
                <p class="subtitle-1">Check Level: {{check_level}}</p>
                <v-spacer></v-spacer>
                <p class="subtitle-1">Sequence: {{taskPackage}}</p>
                <v-spacer></v-spacer>

            </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-row>
            <v-col cols="3" class="pa-0 ma-0">
              <v-list >
                <v-list-group v-for="(item, i) in items" :key="i" @click="reg_no = item.reg_no">
                <template v-slot:activator>
                    <v-list-item   >
                        <v-list-item v-text="item.reg_no"></v-list-item>
                    </v-list-item>
                </template>
                 <v-list-group sub-group no-action v-for="(children) in item.children" :key="children.designation" >
                    <template v-slot:activator>
                    <v-list-item-content  @click="check_level = children.designation">
                            <v-list-item-title>{{children.designation}}</v-list-item-title>
                    </v-list-item-content>
                    </template>

                        <v-list-item
                            v-for="child in item.packs"
                            :key="child.sequence"
                            link
                            @click="getTaskCards(child.sequence)"
                            v-show="(child.check_level == children.designation)"
                        >
                        {{child.sequence}}
                        </v-list-item> 
                 </v-list-group>


               
                

                </v-list-group>

                </v-list>
            </v-col>
            <v-col cols="9" class="grey lighten-4 pa-0">

                 <v-data-table
                 class="grey lighten-4"
                    :search="search"
                    fixed-header
                    dense
                    :headers="headers"
                    :items="desserts"
                    :items-per-page="20"
                >
                    <template v-slot:item.options="{ item}">
                        <v-btn @click="remove(item)" small icon class="mx-2" color="red white--text" ><v-icon>mdi-trash-can</v-icon></v-btn >
                    </template>
                    <template v-slot:item.updated_at="{ item}">
                        {{item.updated_at.split(' ')[0]}}
                    </template>
                    <template v-slot:item.nexDue="{ item}">
                        {{getNexDate(item.updated_at, item.da_r)}}
                    </template>
                    <template v-slot:item.certificate="{ item }">
                    <v-btn  :disabled="(item.certificate == null)?true:false" color="success" small icon target="_blank" link :href="$store.state.path2 + item.certificate"><v-icon>mdi-file-cad</v-icon></v-btn>
                    </template>
                </v-data-table>

                
                 
            </v-col>
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{

         
    
        items:[], 

        filhos:[],

        search:null,
           headers: [
          {
            text: 'S/N', align: 'start', value: 'id', class: "info white--text", width:80,
            
          },
          { text: 'CARD NO', value: 'card_no',class: "info white--text", width:120,},
          { text: 'REV DATE', value: 'rev_date', class: "info white--text", width:120, },
          { text: 'TITLE', value: 'title' ,class: "info white--text", width:200,},
          { text: 'Last Done', value: 'updated_at',class: "info white--text", width:120, },
          { text: 'Nex Due', value: 'nexDue' ,class: "info white--text", width:100,},
          { text: 'Interval', value: 'da_r',class: "info white--text", width:100,},
          { text: 'Options', value: 'options',class: "info white--text", width:140, },
        ],
        desserts:[],
        taskPackage:null,
        reg_no:null,
        check_level:null,

   
      }
  },
  methods:{

      listarAeronaves(){
          const axios = require('axios');

          let dis = this;

           

           let tmp =  this.$store.state.acInformation;

          axios.get(this.$store.state.path + 'maintenance_program').then((response)=>{
             tmp.forEach(element => {
                  element.children  = response.data.data;

                  element.children.forEach(el => {
                       dis.getPackage(el,element)
                  });

                 
              });
              
            dis.items =  tmp;
          });

        
      },
      
      getPackage(item, aircraft){

            aircraft.filhos = []; 
          let dis = this;
           dis.$store.dispatch("loading")
          const axios = require('axios');

            console.log(aircraft.reg_no);
            axios.post(this.$store.state.path+'aircraft_checkPackage', {check_level:item.designation, reg_no:aircraft.reg_no}).then((response)=>{
                
              dis.filhos.push(response.data);

              dis.items.forEach(element => {
                  /* element.packs = []; */
                  
                  if(element.reg_no == aircraft.reg_no){ 
                      /* alert("asfasfasfasf") */
                      element.packs = response.data;

                      console.log("Pegamos", element, aircraft.reg_no)
                  } 
              });
              aircraft.filhos.push(response.data);

               dis.$store.dispatch("nloading")
          });

      },
      getTaskCards(sequence){


          let dis = this;
          dis.$store.dispatch("loading")
          dis.desserts = [];
          dis.taskPackage = sequence;
          const axios = require('axios');

            axios.post(this.$store.state.path+'getMplan_taskcard', {program:sequence}).then((response)=>{

                response.data.forEach(element => {
                    dis.desserts.push(element[0]);
                    console.log(element[0])
                });


                dis.$store.dispatch("nloading")
              
             
          });

      },
      remove(item){
          let dis = this;
          let program = dis.taskPackage;
          let card_no = item.card_no;

            const axios = require('axios');
          axios.post(this.$store.state.path+'getMplan_removeTaskcard', {program:program, card_no:card_no}).then(()=>{
              dis.getTaskCards(program);
          });
      },
      getNexDate(currentDate, days){

          var date1 = new Date(currentDate.split(' ')[0]);
          date1.setDate(date1.getDate()+ parseInt(days) );
          return date1.getFullYear()+  "-" + (date1.getMonth()+1) + "-"+ date1.getDate();
      }
      
  },
  async mounted(){
    this.$store.dispatch('permission');
      let dis = this;
      this.$store.dispatch('getAircraftInformation').then(()=>{
          dis.listarAeronaves();
      });

      this.$store.dispatch('getTaskCard');
        
  }
  

}
</script>

<style>

</style>