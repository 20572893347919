<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
     
      <v-card>
        <v-card-title>
          <span class="headline">Apu Search</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>

              

              <v-col
                cols="12"
                
                 class="py-0"
              >
              <v-autocomplete dense outlined  v-model="dados.reg_no" label="Reg Number" :items="$store.state.acInformation" item-text="reg_no" item-value="reg_no">
              </v-autocomplete>
              </v-col>
              
                

              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  label="SN"
                  dense outlined
                  v-model="dados.sn"
                ></v-text-field>
              </v-col>

               <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  label="VPN"
                  dense outlined
                  v-model="dados.vpn"
                ></v-text-field>
              </v-col>
              

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="$store.state.dialog = false, clear()"
          >
            Close
          </v-btn>
          <v-btn
            color="success darken-1"
            elevation="0"
            @click=" retrieveAll()"
          >
            Retrieve All
          </v-btn>
          <v-btn
            color="info darken-1"
            elevation="0"
            @click=" retrieve()"
          >
            Retrieve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {

    data: () => ({
      /* dialog: false, */
      dados:{
        reg_no:'',
        
              
        vpn:'',
        sn:'',
      }
    }),
    methods:{
      clear(){
        this.dados = {
        reg_no:'',
        
              
        sn:'',
        vpn:'',
      };
      },
      retrieve(){
        this.$store.dispatch("filterApu", this.dados);

        this.$store.state.dialog = false;
      },
      retrieveAll(){
        this.$store.dispatch("getApu");

        this.$store.state.dialog = false;
      }
    },
    computed:{
      dialog(){
        return this.$store.state.dialog;
      }
    }
  }
</script>
