<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" >

        <v-card-title class="px-0" primary-title>
            NEW MAINTENANCE ORDER
        </v-card-title>
        


        <v-row>
                <v-col cols="6">
                    <v-autocomplete label="AC" v-model="dados.reg_no" dense outlined :items="$store.state.acInformation" item-text="reg_no"
                    item-value="reg_no">
                </v-autocomplete>
                
               </v-col>
               
               
                
                
                <v-col cols="6">
                    <v-autocomplete label="ATA" dense v-model="dados.ata" outlined :items="$store.state.ata" item-value="chapter" item-text="chapter">
                    <template v-slot:item="{item}">
                        <v-list-item-content >
                            {{item.chapter}} - {{item.designation}}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
                </v-col>

                <v-col :cols="6">
                      <v-text-field
                            label="STAION"
                            dense
                            outlined
                            v-model="dados.station"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="OPEN DATE"
                            type="date"
                            dense
                            outlined
                            v-model="dados.open_date"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="DUE DATE"
                            type="date"
                            dense
                            outlined
                            v-model="dados.due_date"
                        ></v-text-field>
                </v-col>   
                
                <v-col :cols="6">
                      <v-text-field 
                          
                            label="REF DOC"
                            dense
                            outlined
                            v-model="dados.ref_doc"
                        ></v-text-field>
                </v-col>
                
                
                <v-col :cols="6">
                      <v-text-field
                            label="STEPS"
                            dense
                            outlined
                            v-model="dados.steps"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="TASK CARD NUMBER"
                            dense
                            outlined
                            v-model="dados.task_card_no"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                        <v-textarea
                        outlined dense
                        label="Description"
                        height="90"
                        v-model="dados.description"
                    ></v-textarea>
                </v-col>
                
              
              
              <v-col cols="6">
                    <v-file-input
                        dense outlined
                        label="File"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        v-model="file"
                    ></v-file-input>
                </v-col>
                
                
                
                
                 
                 
               


               <v-row class="justify-end">
                    <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                    <v-btn color="primary" @click="save" elevation="0" class="ml-2">Save</v-btn>
                </v-row>
               

           
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{

          dueDate:false,
          mel:false,
          thisLogIsForMaintenance:'',
          edit:false,
          file:'',
          dados:{
            reg_no:'', task_card_no:'', station:'',
            open_date:'', due_date:'', ref_doc:'',
              ata:'', description:'',
              nha:'', dom:'', docs1:'', docs2:'', int_calendar:'',
                due_hours:'', due_cycles:'', steps:'',
                }
      }
  },
  methods:{
      clear(){
          this.dados={
            reg_no:'', task_card_no:'', station:'',
            open_date:'', due_date:'', ref_doc:'',
              ata:'', description:'',
              nha:'', dom:'', docs1:'', docs2:'', int_calendar:'',
                due_hours:'', due_cycles:'', steps:'',
                }
      },
    // Funcao para salver users na bd
    save() {


        let store = this.$store;
      store.dispatch("loading")
      let axios = require("axios");
      const Swal = require("sweetalert2");
      
      let url = this.$store.state.path + "maintenanceOrder";
      let dis = this;

      if(this.dados.deferral)
        this.dados.next_date=null;
      
       
      url += this.edit ? ("/"+this.dados.id.toString()) + "?_method=PATCH" : "";

       const config = {
        headers: {
            'content-type': 'multipart/form-data'
            ,'Authorization':'Bearer '+window.sessionStorage.getItem("token")
            }
        }

        var form_data = new FormData();

        for ( var key in this.dados ) {
            form_data.append(key, this.dados[key]||'');
        }
        form_data.append('file',this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating maintenanceOrder",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch('getmaintenanceOrder');
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New maintenanceOrder Updated successfuly. "
                  : "maintenanceOrder added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New maintenanceOrder edited successfuly. "
                  : "maintenanceOrder Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
  mounted(){
    this.$store.dispatch("getAircraftInformation");
      this.$store.dispatch('getAta');
      this.$store.dispatch('getPartType');

       if(this.$store.state.editing){

        this.dados = this.$store.state.editingItem;
        this.edit = true;

      }
  }
  

}
</script>

<style>

</style>