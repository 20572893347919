<template>
  <v-app class="app">
    <menuLateral v-if="logged"></menuLateral>

        <loading></loading>
    <router-view>

    </router-view>

  </v-app>
</template>

<script>
import menuLateral from "@/components/menuLateral";
import loading from '@/components/dialog';
export default {
  name: "App",

  components: {
      loading,
    menuLateral,

  },

  data: () => ({
    //
  }),
  computed:{
    logged(){
      return this.$store.state.logged;
    }
  }
};
</script>

<style lang="scss" >

@import "@/assets/scss/app.scss";

.b-r{
  border-right: 1px solid rgb(118, 118, 118);
}
.b-l{
  border-left: 1px solid rgb(169, 169, 169);
}
 
.el-table th /* .el-table tr */{
 background-color: $grey5;
 color: $black1;
}

.bp-dropdown__body{
  box-shadow: none !important;
  border: 1px solid #ccc;
}

.t-l>span, .t-l{
  text-align: left !important;
}
</style>