<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" min-height="450">

        <v-card-title class="px-0 d-flex flex-wrap" primary-title>
            
            <v-col cols="12" class="pa-0 ma-0">
                   <p class="pr-2 pa-0 ma-0">AIRCRAFT MAINTENANCE CHECK SIGN OFF</p>
            </v-col>
        </v-card-title>
        <v-divider></v-divider>

        <v-row>
            <v-col cols="3" class="pa-0 ma-0">
              <v-list >
                <v-list-group v-for="(item, i) in items" :key="i" @click="reg_no = item.reg_no, ac_config = item.config">
                <template v-slot:activator>
                    <v-list-item   >
                        <v-list-item v-text="item.reg_no"></v-list-item>
                    </v-list-item>
                </template>
                 <v-list-group sub-group no-action v-for="(children) in item.children" :key="children.designation" >
                    <template v-slot:activator>
                    <v-list-item-content  @click="check_level = children.designation">
                            <v-list-item-title>{{children.designation}}</v-list-item-title>
                    </v-list-item-content>
                    </template>

                        <v-list-item
                            v-for="child in item.packs"
                            :key="child.sequence"
                            link
                            @click="getTaskCards(child.sequence)"
                              v-show="(child.check_level == children.designation)"
                        >
                        {{child.sequence}}
                        </v-list-item> 
                 </v-list-group>


               
                

                </v-list-group>

                </v-list>
            </v-col>
            <v-col cols="9" class="grey lighten-4 pa-0">
                 <v-col cols="12" class="pa-0 ma-0 d-flex">
             
                    <v-col cols="5">
                        <p class="subtitle-1 pa-0 ma-0 mb-2">Reg No: {{reg_no}}</p>
                        <v-text-field type="date" label="Date In" dense outlined v-model="dados.iput_date" ></v-text-field>
                        <v-row>
                           <v-col cols="6" class="pa-0 ma-0 px-2">
                                <v-text-field label="A/C Hours" dense outlined v-model="dados.ac_time" ></v-text-field>
                           </v-col>
                           <v-col cols="6" class="pa-0 ma-0 px-2">
                                <v-text-field  label="A/C Minutes" dense outlined v-model="dados.ac_minutes" ></v-text-field>
                           </v-col>
                        </v-row>

                    </v-col>
                    <v-col cols="4">
                        <p class="subtitle-1 pa-0 ma-0 mb-2">Check Level: {{check_level}}</p>
                        <v-text-field type="date" label="Date Out" dense outlined v-model="dados.output_date" ></v-text-field>
                        <v-text-field  label="A/C Cycles" dense outlined v-model="dados.ac_cycles" ></v-text-field>
                        
                    </v-col>
                    <v-col cols="3">
                        <p class="subtitle-1 pa-0 ma-0 mb-2">Sequence: {{taskPackage}}</p>
                        <v-text-field  label="Project" dense outlined v-model="dados.project" ></v-text-field>
                        <v-text-field  label="Station" dense outlined v-model="dados.stn" ></v-text-field>

                    </v-col>
                    
                    

                </v-col>

                 <v-data-table
                 class="grey lighten-4"
                    :search="search"
                    fixed-header
                    dense
                    :headers="headers"
                    :items="desserts"
                    :items-per-page="10"
                >
                    <template v-slot:item.accomplished="{item}">
                        <v-checkbox
                        @change="addItem(item)"
                        v-model="item.accomplished"
                     
                    ></v-checkbox>
                        <!-- <v-btn @click="remove(item)" small icon class="mx-2" color="red white--text" ><v-icon>mdi-trash-can</v-icon></v-btn > -->
                    </template>
                 
                </v-data-table>

                <v-row class="pa-3">
                    <v-spacer></v-spacer>
                    <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                    <v-btn color="primary" @click="save" :disabled=$store.state.permission elevation="0" class="ml-2">Save</v-btn>
                </v-row>

                
                 
            </v-col>
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{
    
        items:[], 

        filhos:[],

        search:null,
           headers: [
          {
            text: 'S/N', align: 'start', value: 'id', class: "info white--text", width:80,
            
          },
          { text: 'CARD NO', value: 'card_no',class: "info white--text", width:110,},
          { text: 'REV DATE', value: 'rev_date', class: "info white--text", width:110, },
          { text: 'TITLE', value: 'title' ,class: "info white--text", width:200,},
          { text: 'ACCOMPLISHED',  value: 'accomplished',class: "info white--text", width:140, },
        ],
        desserts:[],
        taskPackage:null,
        reg_no:null,
        ac_config:null,
        check_level:null,


        dados:{

            ac_config: '',
            reg_no: '',
            level: '',
            sequence: '',
            iput_date: '',
            output_date: '',
            ac_time: '',
            ac_cycles: '',
            nex_due: '',
            hours: '',
            cycles: '',
            days: '',
            stn: '',
            project: '',
            check_def: '',
            sign_off_doc: '',

        },
        cards:[],

   
      }
  },
  
  methods:{

      addItem(item){

          let index = this.cards.indexOf(item);

          if(index==-1)
            this.cards.push(item);

        if(index==1)
            this.cards.splice(index,1);

            console.log(this.cards);

      },
      save(){



      this.dados.level = this.check_level;
      this.dados.sequence = this.taskPackage;
      this.dados.reg_no = this.reg_no;
      this.dados.ac_config = this.ac_config;

      this.dados.cards = JSON.stringify(this.cards);
      var days = '45';
      switch (this.ac_config) {
          case '737-700':
              days = '90';
              break;
          case '777-200':
              days = '45';
              break;
          case '777-300':
              days = '45';
              break;
         
          case 'DHC 8-Q400':
              days = '800';
              break;
      
          default:
               days = '45';
              break;
      }
      this.dados.nex_due = this.getNexDate(this.dados.output_date, days);
      let dis  = this;
      const axios = require('axios');

        dis.$store.dispatch('loading');
      axios.post(this.$store.state.path+"aircraft_check_history", this.dados).then((response)=>{

          console.info("resposta: ", response);

          dis.cards.forEach(element=>{

              if(element.accomplished){

                 let form = new  FormData();
                 
                 form.append('package',dis.taskPackage);
                 form.append('card_no',element.card_no);
                 form.append('date',dis.dados.iput_date);
                 form.append('fh',dis.dados.ac_time);
                 form.append('fc',dis.dados.ac_cycles);
                 form.append('interval','45');
                 form.append('man_hours',element.estimate);
                
                axios.post(this.$store.state.path+"task_card_last_done",form).then(response=>{
                    console.info(response);
                });

              }
          })

          dis.$store.dispatch('nloading');
      }).then(()=>{
          dis.$store.dispatch('nloading');

      });



  },

      listarAeronaves(){
          const axios = require('axios');

          let dis = this;

           

           let tmp =  this.$store.state.acInformation;

          axios.get(this.$store.state.path + 'maintenance_program').then((response)=>{
             tmp.forEach(element => {
                  element.children  = response.data.data;

                  element.children.forEach(el => {
                       dis.getPackage(el, element)
                  });

                 
              });
              
            dis.items =  tmp;
          });

        
      },
      
      getPackage(item, aircraft){


          let dis = this;
           dis.$store.dispatch("loading")
          const axios = require('axios');

            axios.post(this.$store.state.path+'aircraft_checkPackage', {check_level:item.designation, reg_no:aircraft.reg_no}).then((response)=>{
              
           
              dis.items.forEach(element => {
                  /* element.packs = []; */
                
                  if(element.reg_no == aircraft.reg_no){ 
                      /* alert("asfasfasfasf") */
                      element.packs = response.data;
                     
                  } 
                 /*  console.log(element) */
              });

              dis.filhos.push(response.data);

               dis.$store.dispatch("nloading")
          });

      },
      getTaskCards(sequence){

          
           this.cards = [];
          let dis = this;
          dis.$store.dispatch("loading")
          dis.desserts = [];
          dis.taskPackage = sequence;
          const axios = require('axios');

            axios.post(this.$store.state.path+'getMplan_taskcard', {program:sequence}).then((response)=>{


                /*  dis.desserts = response.data
 */
                 response.data.forEach(element => {
                   

                    dis.desserts.push(element[0]);
                   /*  console.log(element[0]) */
                }); 

                console.log(dis.desserts);

                dis.$store.dispatch("nloading")
              
             
          });

      },
      remove(item){
          let dis = this;
          let program = dis.taskPackage;
          let card_no = item.card_no;

            const axios = require('axios');
          axios.post(this.$store.state.path+'getMplan_removeTaskcard', {program:program, card_no:card_no}).then(()=>{
              dis.getTaskCards(program);
          });
      },
      getNexDate(currentDate, days){

          var date1 = new Date(currentDate.split(' ')[0]);
          date1.setDate(date1.getDate()+ parseInt(days) );
          return date1.getFullYear()+  "-" + (date1.getMonth()+1) + "-"+ date1.getDate();
      }
      
  },
  async mounted(){
      let dis = this;
      this.$store.dispatch('permission');
      this.$store.dispatch('getAircraftInformation').then(()=>{
          dis.listarAeronaves();
      });

      this.$store.dispatch('getTaskCard');
        
  }
  

}
</script>

<style>

</style>