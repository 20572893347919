<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" min-height="450">

        <v-card-title class="px-0" primary-title>
            AIRCRAFT MAINTENANCE CHECK PLAN
        </v-card-title>
        <v-divider></v-divider>

        <v-row>
            <v-col cols="3">
              <v-list>
                <v-list-group v-for="(item, i) in items" :key="i" >
                <template v-slot:activator>
                    <v-list-item  link @click="pacote.reg_no = item.reg_no, pacote.ac_config = item.config,  pacote.check_level= null">
                        <v-list-item-title v-text="item.reg_no"></v-list-item-title>
                    </v-list-item>
                </template>
                 <v-list-group sub-group no-action v-for="children in item.children" :key="children.designation" >
                    <template v-slot:activator>
                    <v-list-item-content @click="pacote.check_level = children.designation, getPackage(children)">
                            <v-list-item-title> {{children.designation}}</v-list-item-title>
                    </v-list-item-content>
                    </template>

                          <v-list-item
                            v-for="child in item.packs"
                            :key="child.sequence"
                            link
                            
                            v-show="(child.check_level == children.designation)"
                        >
                        {{child.sequence}}
                        </v-list-item> 
                 </v-list-group>


               
                

                </v-list-group>

                </v-list>
            </v-col>
            <v-col class="grey lighten-4">

                
                 <v-row>
                     <v-col cols="6">
                         <h4>A/C Reg No: {{pacote.reg_no}}</h4>
                            <v-text-field v-model="pacote.sequence"    label="*Sequence"  dense outlined   ></v-text-field>
                            <v-text-field  v-model="pacote.input_date"   label="*Input Date"  dense outlined  type="date" ></v-text-field>
                            <v-text-field   v-model="pacote.output_date"  label="Output Date" type="date"  dense outlined ></v-text-field>
                            <v-text-field   v-model="pacote.ac_hours"   label="A/C Hours"  dense outlined ></v-text-field>
                            <!-- <v-text-field   v-model="pacote.ac_hours"  label="A/C Minutes"  dense outlined ></v-text-field> -->
                            <v-text-field  v-model="pacote.ac_cycles"   label="A/C Cycles"  dense outlined ></v-text-field>
                     </v-col>
                     <v-col cols="6">
                          <h4>Check Level: {{pacote.check_level}}</h4>
                            <v-text-field   v-model="pacote.station"  label="*Station"  dense outlined   ></v-text-field>
                            <v-text-field   v-model="pacote.project"  label="Project"  dense outlined ></v-text-field>
                            <v-autocomplete
                                v-model="pacote.taskCards"
                                :items="$store.state.taskCard"
                                outlined
                                dense
                                chips
                                item-text="card_no"
                                item-value="card_no"
                                small-chips
                                label="Task Cards"
                                multiple
                                height="105"
                            >


                            <template v-slot:item="{item}">
                                <v-list-item-action>
                                    <v-checkbox ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content aria-checked >
                                    {{item.card_no}} -  {{item.title}}
                                </v-list-item-content>
                            </template>
                            
                            </v-autocomplete>
                            <v-btn color="info" @click="clear" elevation="0" class="ml-2">Cancel</v-btn>
                            <v-btn color="primary" @click="save" :disabled=$store.state.permission elevation="0" class="ml-2">Save</v-btn>
                            <!-- <v-text-field     label="A/C Minutes"  dense outlined ></v-text-field>
                            <v-text-field     label="A/C Cycles"  dense outlined ></v-text-field> -->

                     </v-col>
                 </v-row>
            </v-col>
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{
    pacote:{
            reg_no: '',
            ac_config: '',
            check_level: '',
            sequence: '',
            station: '',
            input_date: '',
            ac_hours: '',
            ac_cycles: '',
            project: '',
            output_date: '',
            taskCards:[],
          },
        items:[], 
        filhos:[],

   
      }
  },
  methods:{

      listarAeronaves(){
          const axios = require('axios');

          let dis = this;

           

           let tmp =  this.$store.state.acInformation;

          axios.get(this.$store.state.path + 'maintenance_program').then((response)=>{
             tmp.forEach(element => {
                  element.children  = response.data.data;

                  element.children.forEach(el => {
                       dis.getPackage(el,element)
                  });
                 
              });
              
            dis.items =  tmp;
              
          });

        
      },
      clear(){

           this.pacote={
            reg_no: '',
            check_level: '',
            sequence: '',
            station: '',
            input_date: '',
            ac_hours: '',
            ac_cycles: '',
            project: '',
            output_date: '',
            taskCards:[],
          }
      },
      save(){
        let dis = this;
        const axios = require('axios');
        let n = [];
          dis.$store.dispatch("loading")
        this.pacote.taskCards.forEach(element=>{
            n.push({taskCard:element, program:this.pacote.sequence})
        });
        this.pacote.taskCards = n;

        console.info(this.pacote);

        axios.post(dis.$store.state.path + 'aircraft_check', dis.pacote).then((response)=>{

            n.forEach(el => {
                axios.post(dis.$store.state.path + 'mplan_taskcard', el).then(resp=>{
                  dis.check_level.children.packs.push(resp.data)  
                  console.info(response);                   
                })
            });

            dis.pacote.taskCard = [];
            dis.pacote = {};
            dis.$store.dispatch("nloading")
        })
          
      },
      getPackage(item, aircraft){
          aircraft.filhos = []; 
          let dis = this;
           dis.$store.dispatch("loading")
          const axios = require('axios');

            console.log(aircraft.reg_no);
            axios.post(this.$store.state.path+'aircraft_checkPackage', {check_level:item.designation, reg_no:aircraft.reg_no}).then((response)=>{
                
              dis.filhos.push(response.data);

              dis.items.forEach(element => {
                  /* element.packs = []; */
                  
                  if(element.reg_no == aircraft.reg_no){ 
                      /* alert("asfasfasfasf") */
                      element.packs = response.data;

                      console.log("Pegamos", element, aircraft.reg_no)
                  } 
              });
              aircraft.filhos.push(response.data);

               dis.$store.dispatch("nloading")
          });

      }
      
  },
  async mounted(){
    this.$store.dispatch('permission');
      let dis = this;
      this.$store.dispatch('getAircraftInformation').then(()=>{
          dis.listarAeronaves();
      });

      this.$store.dispatch('getTaskCard');
        
  }
  

}
</script>

<style>

</style>