<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
     
      <v-card>
        <v-card-title>
          <span class="headline">Instalation/Removed Search</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Tag No"
                  dense outlined v-model="dados.tag_no"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
                 class="py-0"
              >
              <v-autocomplete dense outlined  v-model="dados.reg_no" label="Reg Number" :items="$store.state.acInformation" item-text="reg_no" item-value="reg_no">
              </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
                 class="py-0"
              >
              <v-autocomplete dense outlined  v-model="dados.aircraft_type" label="Aircraft Type" :items="$store.state.parts" item-text="model" item-value="model">
                
              </v-autocomplete>
              </v-col>
             
              
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Shelf Life Date"
                  type="date"
                  dense outlined
                  v-model="dados.shelf_life_date"
                ></v-text-field>
              </v-col>
             
              

            

               <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  label="Part Nº"
                  dense outlined
                  v-model="dados.part_no"
                ></v-text-field>
              </v-col>
              

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="$store.state.dialog = false, clear()"
          >
            Close
          </v-btn>
          <v-btn
            color="success darken-1"
            elevation="0"
            @click=" retrieveAll()"
          >
            Retrieve All
          </v-btn>
          <v-btn
            color="info darken-1"
            elevation="0"
            @click=" retrieve()"
          >
            Retrieve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {

    data: () => ({
      /* dialog: false, */
      dados:{
        reg_no:'',
        tag_no:'',
        aircraft_type:'',
        shelf_life_date:'',
        part_no:'',
        
      }
    }),
    methods:{
      clear(){
        this.dados = {
          reg_no:'',
        tag_no:'',
        aircraft_type:'',
        shelf_life_date:'',
        part_no:'',
      };
      },
      retrieve(){
        this.$store.dispatch("filterPartInstalation", this.dados);

        this.$store.state.dialog = false;
      },
      retrieveAll(){
        this.$store.dispatch("getPartInstalation");

        this.$store.state.dialog = false;
      }
    },
    computed:{
      dialog(){
        return this.$store.state.dialog;
      }
    }
  }
</script>
