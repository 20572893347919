<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" >

        <v-card-title class="px-0" primary-title>
            {{$t('newLogEntry')}}
        </v-card-title>
        <v-divider></v-divider>


        <v-row>
            <v-col cols="6">

                <v-text-field
                    label="REG NO"
                    dense
                    outlined
                    v-model="dados.reg_no"
                ></v-text-field>
                <v-text-field
                    label="Log Page No"
                    dense
                    outlined
                    v-model="dados.log_page_no"
                ></v-text-field>
                 <v-radio-group v-model="dados.for_maintenance">
                     <v-radio label="This log is for maintenance"></v-radio>
                     <v-radio label="This log is VOID"></v-radio>
                 </v-radio-group>
                 <v-text-field
                    label="Previous Log Page No"
                    dense
                    outlined
                    v-model="dados.previous_log_page_no"
                ></v-text-field>
                 <v-text-field
                    label="FLight No"
                    dense
                    outlined
                    v-model="dados.flight_no"
                ></v-text-field>
                <v-text-field
                    label="Dep"
                    dense
                    outlined
                    v-model="dados.dep"
                ></v-text-field>
                <v-text-field
                    label="Arr"
                    dense
                    outlined
                    v-model="dados.arr"
                ></v-text-field>
                <v-text-field
                    label="Block Off (GMT)"
                    dense
                    outlined
                     v-model="dados.block_off_gmt"
                ></v-text-field>
                <v-text-field
                    label="Take Off Time (GMT)"
                    dense
                    outlined
                    v-model="dados.take_off_time_gmt"
                ></v-text-field>
                <v-text-field
                    label="Landing Time (GMT)"
                    dense
                    outlined
                    v-model="dados.landing_time_gmt"
                ></v-text-field>
                <v-text-field
                    label="Block On (GMT)"
                    dense
                    outlined
                    v-model="dados.block_on_gmt"
                ></v-text-field>
                <v-text-field
                    label="FLight Time (GMT)"
                    dense
                    outlined
                    v-model="dados.flight_time_gmt"
                ></v-text-field>
                <v-text-field
                    label="Block Time (GMT)"
                    dense
                    outlined
                    v-model="dados.block_time_gmt"
                ></v-text-field>
                <h4>Auto Land</h4>
                <v-radio-group v-model="dados.auto_land">
                     <v-radio label="Accepted"></v-radio>
                     <v-radio label="This log is VOID"></v-radio>
                 </v-radio-group>


            </v-col>
            <v-col cols="6">
                <v-checkbox label="APU Inflight Start Attempted:" v-model="dados.apu_inflight_start_attempted"></v-checkbox>
                 <v-text-field
                    label="Oil UpLift (Engine No 1)"
                    dense
                    outlined
                    v-model="dados.oil_up_lift_01"
                ></v-text-field>
                 <v-text-field
                    label="Oil UpLift (Engine No 2)"
                    dense
                    outlined
                    v-model="dados.oil_up_lift_02"
                ></v-text-field>
                 <v-text-field
                    label="Oil UpLift (Engine No 3)"
                    dense
                    outlined
                    v-model="dados.oil_up_lift_03"
                ></v-text-field>
                 <v-text-field
                    label="Oil UpLift (Engine No 4)"
                    dense
                    outlined
                    v-model="dados.oil_up_lift_04"
                ></v-text-field>
                 <v-text-field
                    label="APU Oil"
                    dense
                    outlined
                    v-model="dados.apu_oil"
                ></v-text-field>
                 <v-text-field
                    label="Hydraulic Fuild 1(Left Or A)"
                    dense
                    outlined
                    v-model="dados.hydraulic_fluid_1"
                ></v-text-field>
                 <v-text-field
                    label="Hydraulic Fluid 2(Right Or B)"
                    dense
                    outlined
                    v-model="dados.hydraulic_fluid_2"
                ></v-text-field>
                 <v-text-field
                    label="Hydraulic Fuild 3(Center Or Aux)"
                    dense
                    outlined
                    v-model="dados.hydraulic_fluid_3"
                ></v-text-field>
                 <v-text-field
                    label="Hydraulic Fuild 4"
                    dense
                    outlined
                    v-model="dados.hydraulic_fluid_4"
                ></v-text-field>
                <h4>Fuel</h4>
                 <v-text-field
                    label="Fuel On Board"
                    dense
                    outlined
                    v-model="dados.fuel_on_board"
                ></v-text-field>
                 <v-text-field
                    label="Fuel Used"
                    dense
                    outlined
                    v-model="dados.fuel_used"
                ></v-text-field>
                 <v-text-field
                    label="Fuel Remaining"
                    dense
                    outlined
                    v-model="dados.fuel_remaining"
                ></v-text-field>
                <v-text-field
                    label="Fuel Added"
                    dense
                    outlined
                    v-model="dados.fuel_added"
                ></v-text-field>
                
                <v-file-input
                dense outlined
                label="File Uploaded"
                v-model="dados.file_upload"
                ></v-file-input>

                <v-row class="justify-end">
                    <v-btn color="info" elevation="0" class="ml-2">{{$t('cancel')}}</v-btn>
                    <v-btn color="primary" @click="save" :disabled=$store.state.permission elevation="0" class="ml-2">{{$t('save')}}</v-btn>
                </v-row>
                
            </v-col>
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{
          thisLogIsForMaintenance:'',

          dados:{
                reg_no: null,
                log_page_no: null,
                for_maintenance: null,
                previous_log_page_no: null,
                flight_no: null,
                dep: null,
                arr: null,
                block_off_gmt: null,
                take_off_time_gmt: null,
                landing_time_gmt: null,
                block_on_gmt: null,
                flight_time_gmt: null,
                block_time_gmt: null,
                etops: null,
                rvsm: null,
                auto_land: null,
                is_void: null,
                apu_inflight_start_attempted: null,
                oil_up_lift_01: null,
                oil_up_lift_02: null,
                oil_up_lift_03: null,
                oil_up_lift_04: null,
                apu_oil: null,
                hydraulic_fluid_1: null,
                hydraulic_fluid_2: null,
                hydraulic_fluid_3: null,
                hydraulic_fluid_4: null,
                fuel_on_board: null,
                fuel_used: null,
                fuel_remaining: null,
                fuel_added: null,
                file_upload: null,
                }
      }
  },
  methods:{
      clear(){
          this.dados={
                reg_no: null,
                log_page_no: null,
                for_maintenance: null,
                previous_log_page_no: null,
                flight_no: null,
                dep: null,
                arr: null,
                block_off_gmt: null,
                take_off_time_gmt: null,
                landing_time_gmt: null,
                block_on_gmt: null,
                flight_time_gmt: null,
                block_time_gmt: null,
                etops: null,
                rvsm: null,
                auto_land: null,
                is_void: null,
                apu_inflight_start_attempted: null,
                oil_up_lift_01: null,
                oil_up_lift_02: null,
                oil_up_lift_03: null,
                oil_up_lift_04: null,
                apu_oil: null,
                hydraulic_fluid_1: null,
                hydraulic_fluid_2: null,
                hydraulic_fluid_3: null,
                hydraulic_fluid_4: null,
                fuel_on_board: null,
                fuel_used: null,
                fuel_remaining: null,
                fuel_added: null,
                file_upload: null,
                }
      },
    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading")
      let axios = require("axios");
      const Swal = require("sweetalert2");
      const config = {
        headers: {
            
            'Authorization':'Bearer '+window.sessionStorage.getItem("token")
            }
        }

      let url = this.$store.state.path + "acLog";
      let dis = this;

      url += this.edit ? this.dados.id.toString() + "?_method=PATCH" : "";

      axios
        .post(url, this.dados,config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating users",
              "error"
            );
          else {
            if (!dis.edit) {
              store.state.aircraftLog.push(response.data);
              dis.clear;
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New User added successfuly. "
                  : "User Updated successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New User edited successfuly. "
                  : "User Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
  

}
</script>

<style>

</style>