<template>
  <div>
 <cabeca></cabeca>

 
  <v-container grid-list-xs>
      <v-card
     class="mx-auto pa-2 px-10 mb-10"
     outlined
     color="white" >

     <v-card-title class="px-0" primary-title>
         
         <v-row class="justify-space-between">
            <h3>ADD SERVICE BULLETIN ENTRY PAGE</h3>
          <v-btn  small route to="/serviceBulletinList" color="info" class="elevation-0 ma-0 mb-2"> <v-icon class="mr-3">mdi-clipboard-list</v-icon> Service Bulletin List</v-btn>
        </v-row>
     </v-card-title>
     
     <v-divider></v-divider>


     <v-row>
              <v-col cols="6">
                    <v-autocomplete label="AC" v-model="dados.reg_no" dense outlined :items="$store.state.acInformation" item-text="reg_no"
                    item-value="reg_no">
                  </v-autocomplete>
                
               </v-col>
            
            
             
             
             <v-col cols="6">
                 <v-autocomplete label="ATA CHAPTER" dense v-model="dados.ata" outlined :items="$store.state.ata" item-value="chapter" item-text="chapter">
                 <template v-slot:item="{item}">
                     <v-list-item-content >
                         {{item.chapter}} - {{item.designation}}
                     </v-list-item-content>
                 </template>
             </v-autocomplete>
             </v-col>

             <v-col :cols="6">
                   <v-text-field
                         label="Title"
                         dense
                         outlined
                         v-model="dados.title"
                     ></v-text-field>
             </v-col>
             <v-col cols="6">
                 <v-autocomplete label="Sb Type" v-model="dados.sb_type" dense outlined :items="$store.state.sb_type" item-text="sb_type"
                 item-value="sb_type">
             </v-autocomplete>
            </v-col>
             <v-col :cols="6">
                   <v-text-field
                         label="Sb nº"
                         dense
                         outlined
                         v-model="dados.sb_no"
                     ></v-text-field>
             </v-col>
             <v-col :cols="6">
                   <v-text-field
                         label="Rev nº"
                         dense
                         outlined
                         v-model="dados.rev_no"
                     ></v-text-field>
             </v-col>
             

           <v-col :cols="edit?3:4">
               <v-text-field
               label="Rev Date"
               type="date"
               dense
               outlined
               v-model="dados.rev_date"
           ></v-text-field>
             </v-col>
             <v-col :cols="6">   
                      <v-checkbox
                       label="This Repetitive"
                       v-model="dados.status" >
                      </v-checkbox></v-col>
                
                <v-col :cols="6">
                  <v-text-field
                  attach  v-if="!dados.status"
                    label="NEXT DATE"
                    type="date"
                    dense
                    outlined
                    v-model="dados.next_date"
                  ></v-text-field>
                </v-col>
                   
                <v-row attach  v-if="dados.status">
                <v-col :cols="6">
                      <v-text-field 
                          
                            label="INT. CALENDER"
                            dense
                            outlined
                            v-model="dados.int_calendar"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="INT. HOURS"
                            dense
                            outlined
                            v-model="dados.int_hours"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="INT. CYCLES"
                            dense
                            outlined
                            v-model="dados.int_cycles"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="LAST DATE"
                            type="date"
                            dense
                            outlined
                            v-model="dados.last_date"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="LAST HOURS"
                            dense
                            outlined
                            v-model="dados.last_hours"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="LAST CYCLES"
                            dense
                            outlined
                            v-model="dados.last_cycles"
                        ></v-text-field>
                </v-col>
                
              </v-row>
             
             <v-col cols="6">
                    <v-file-input
                        dense outlined
                        label="File"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        v-model="file"
                    ></v-file-input>
                </v-col>
                 
             


            <v-row class="justify-end">
                 <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                 <v-btn color="primary" @click="save" :disabled=$store.state.permission elevation="0" class="ml-2">Save</v-btn>
             </v-row>
            

        
     </v-row>

     </v-card>

  </v-container> 
 </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
components: {
 cabeca,
},
data(){
   return{

       dueDate:false,
       mel:false,
       thisLogIsForMaintenance:'',
       edit:false,
      file:'',
       dados:{
            
             reg_no:'',
             sb_type:'',
             ata:'',
             sb_no:'',
             title:'',
             rev_no:'',	
             start_process:'',
             rev_date:'',
             file:'',
             int_calendar:'',
            int_hours:'', int_cycles:'', last_date:'', last_hours:'',
            last_cycles:'',  close_date:'',status:''

            
             }
   }
},
methods:{
   clear(){
       this.dados={
             reg_no:'',
             sb_type:'',
             ata:'',
             sb_no:'',
             title:'',
             rev_no:'',	
             start_process:'',
             
             
             rev_date:'',
             mtbr:''
            
             }
   },
 // Funcao para salver users na bd
 save() {


let store = this.$store;
store.dispatch("loading")
let axios = require("axios");
const Swal = require("sweetalert2");

let url = this.$store.state.path + "serviceBulletin";
let dis = this;

url += this.edit ? ("/"+this.dados.id.toString()) + "?_method=PATCH" : "";

const config = {
headers: {
 'content-type': 'multipart/form-data',
 'Authorization':'Bearer '+window.sessionStorage.getItem("token")
 }
}

var form_data = new FormData();

for ( var key in this.dados ) {
 form_data.append(key, this.dados[key]||'');
}
form_data.append('file',this.file);

axios
.post(url, form_data, config)
.then(function(response) {
store.dispatch("nloading")
if (typeof response.data == "string")
 Swal.fire(
   "Oops...",
   !dis.edit
     ? "There was an error while saving. "
     : "There was an error while Updating Documments",
   "error"
 )
else {
 if (!dis.edit) {
   store.dispatch('getDocumments');
   dis.clear();
   Swal.fire(
     "Success!",
     !dis.edit
       ? "New Documments Updated successfuly. "
       : "PARTS added successfuly.",
     "success"
   );
 } else {
   Swal.fire(
     "Success!",
     !dis.edit
       ? "New PARTS edited successfuly. "
       : "PARTS Updated successfuly.",
     "success"
   );
 }
}
})
.catch(function(error) {
store.dispatch("nloading")
Swal.fire("Oops...", "Unable to save. " + error, "error");
console.info(error);
});
},
},
mounted(){
 this.$store.dispatch('permission');
 this.$store.dispatch('getAcType');
   this.$store.dispatch('getAta');
   this.$store.dispatch('getPartType');
   this.$store.dispatch("getAircraftInformation");

    if(this.$store.state.editing){

     this.dados = this.$store.state.editingItem;
     this.edit = true;

   }
}


}
</script>

<style>

</style>