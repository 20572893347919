<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" >

        <v-card-title class="px-0" primary-title>
            NEW AD ENTRY PAGE
        </v-card-title>
        


        <v-row>
                <v-col cols="6">
                    <v-autocomplete label="AC" v-model="dados.reg_no" dense outlined :items="$store.state.acInformation" item-text="reg_no"
                    item-value="reg_no">
                </v-autocomplete>
                
               </v-col>
               
               
                
                
                <v-col cols="6">
                    <v-autocomplete label="AD TYPE" dense v-model="dados.ad_type" outlined :items="$store.state.ad_type" item-value="ad_type" item-text="ad_type">
                    <template v-slot:item="{item}">
                        <v-list-item-content >
                            {{item.ad_type}}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
                </v-col>

                <v-col :cols="6">
                      <v-text-field
                            label="TITLE"
                            dense
                            outlined
                            v-model="dados.title"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="AMENDMENT"
                            dense
                            outlined
                            v-model="dados.amendment"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="WEB LINK"
                            dense
                            outlined
                            v-model="dados.web_link"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="AUTHORITY"
                            dense
                            outlined
                            v-model="dados.authority"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="SUBJECT"
                            dense
                            outlined
                            v-model="dados.subject"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="SERIAL NUMBER"
                            dense
                            outlined
                            v-model="dados.seria_no"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="METHOD OF COMPLIANCE"
                            dense
                            outlined
                            v-model="dados.method_compliance"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="AD DESCRIPTION"
                            dense
                            outlined
                            v-model="dados.ad_description"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                  <v-text-field
                    label="EFFECTIVE DATE"
                    type="date"
                    dense
                    outlined
                    v-model="dados.effective_date"
                  ></v-text-field>
                </v-col>
                
                <v-col :cols="6">
                  <v-text-field
                    label="ISSUE DATE"
                    type="date"
                    dense
                    outlined
                    v-model="dados.issue_date"
                  ></v-text-field>
                </v-col>
               
                     
                <v-col :cols="6">   
                      <v-checkbox
                       label="This Repetitive"
                       v-model="dados.status" >
                      </v-checkbox></v-col>
                
                <v-col :cols="6">
                  <v-text-field
                  attach  v-if="!dados.status"
                    label="NEXT DATE"
                    type="date"
                    dense
                    outlined
                    v-model="dados.next_date"
                  ></v-text-field>
                </v-col>
                   
                <v-row attach  v-if="dados.status">
                <v-col :cols="6">
                      <v-text-field 
                          
                            label="INT. CALENDER"
                            dense
                            outlined
                            v-model="dados.int_calendar"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="INT. HOURS"
                            dense
                            outlined
                            v-model="dados.int_hours"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="INT. CYCLES"
                            dense
                            outlined
                            v-model="dados.int_cycles"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="LAST DATE"
                            type="date"
                            dense
                            outlined
                            v-model="dados.last_date"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="LAST HOURS"
                            dense
                            outlined
                            v-model="dados.last_hours"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="LAST CYCLES"
                            dense
                            outlined
                            v-model="dados.last_cycles"
                        ></v-text-field>
                </v-col>
                
              </v-row>
              
              <v-col cols="6">
                    <v-file-input
                        dense outlined
                        label="File"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        v-model="file"
                    ></v-file-input>
                </v-col>
                
                
                
                
                 
                 
               


               <v-row class="justify-end">
                    <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                    <v-btn color="primary" @click="save" elevation="0" class="ml-2">Save</v-btn>
                </v-row>
               

           
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{

          dueDate:false,
          mel:false,
          thisLogIsForMaintenance:'',
          edit:false,
          file:'',
          dados:{
            reg_no:'', title:'', seria_no:'',
            web_link:'', amendment:'', authority:'', method_compliance:'',
              ad_type:'', ad_description:'',
              subject:'',  int_calendar:'',
                int_hours:'', int_cycles:'', last_date:'', last_hours:'',
                last_cycles:'',  close_date:'',status:''
                }
      }
  },
  methods:{
      clear(){
          this.dados={
            reg_no:'', title:'', seria_no:'',
            web_link:'', amendment:'', authority:'', method_compliance:'',
              ad_type:'', ad_description:'',
              subject:'',  int_calendar:'',
                int_hours:'', int_cycles:'', last_date:'', last_hours:'',
                last_cycles:'',  close_date:'',status:''
               
                }
      },
    // Funcao para salver users na bd
    save() {


        let store = this.$store;
      store.dispatch("loading")
      let axios = require("axios");
      const Swal = require("sweetalert2");
      
      let url = this.$store.state.path + "adEntryPage";
      let dis = this;

      if(this.dados.status)
        this.dados.next_date=null;
      
       
      url += this.edit ? ("/"+this.dados.id.toString()) + "?_method=PATCH" : "";

       const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization':'Bearer '+window.sessionStorage.getItem("token")
            }
        }

        var form_data = new FormData();

        for ( var key in this.dados ) {
            form_data.append(key, this.dados[key]||'');
        }
        form_data.append('file',this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating adEntryPage",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch('getAdEntryPage');
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New adEntryPage Updated successfuly. "
                  : "adEntryPage added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New adEntryPage edited successfuly. "
                  : "adEntryPage Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
  mounted(){
    this.$store.dispatch("getAircraftInformation");
      this.$store.dispatch('getAta');
      this.$store.dispatch('getPartType');

       if(this.$store.state.editing){

        this.dados = this.$store.state.editingItem;
        this.edit = true;

      }
  }
  

}
</script>

<style>

</style>