<template>
  <div>
        <cabeca></cabeca>

         <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="pa-0" primary-title>
          <span>TASK CARD LAST DONE LIST</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-account-search"
            dense
            outlined
            label="Search"
            class="ma-0 pa-0"
          ></v-text-field>
         
          <v-row class="justify-end">
             <!-- <v-btn color="info" class="mr-2 mt-1" depressed small route to="/newTaskCard">Add New AC </v-btn> -->
             <v-btn color="primary" class="mr-10" icon> <v-icon>mdi-filter-menu</v-icon></v-btn>
            <download-excel
              header="Aircraft Information"
              name="Users List"
              :data="tcLastDone"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <v-btn color="red"   icon><v-icon>mdi-file-pdf</v-icon></v-btn>
            <v-btn color="info" icon><v-icon>mdi-printer</v-icon></v-btn>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

      

            <v-card flat height="350">
              <v-data-table
                height="300"
                :search="search"
                fixed-header
                dense
                :headers="headers"
                :items="tcLastDone"
                :items-per-page="5"
              >
                <template v-slot:item.options="{ item, index }">
                  <v-btn
                    @click="editar(item)"
                    :disabled=$store.state.permission
                    small
                    icon
                    class="mx-2"
                    color="orange"
                    ><v-icon>mdi-clipboard-edit</v-icon></v-btn
                  >
                  <v-btn
                    @click="remove(index, item)"
                    :disabled=$store.state.permission
                    small
                    icon
                    class="mx-2"
                    color="red white--text"
                    ><v-icon>mdi-trash-can</v-icon></v-btn
                  >
                </template>
                <template v-slot:item.nex_date="{ item }">
                 <v-chip>
                   {{nextDate(item.date, item.interval)}}
                 </v-chip>
                </template>
                <!-- <template v-slot:item.status>
                    <div style="width:18px; height:18px;" class=" info rounded-circle"></div>
                </template>
                <template v-slot:item.upload="{ item }">
                  <v-btn  color="success" disabled small icon @click="upload(item.id)"><v-icon>mdi-file-upload</v-icon></v-btn>
                </template>
                <template v-slot:item.treshould="{ item }">
                 {{tirarBarra([item.fh_t,item.fc_t, item.da_t, item.mo_t, item.ye_t])}}
                </template>
                <template v-slot:item.repeat="{ item }">
                 {{tirarBarra([item.fh_r,item.fc_r, item.da_r, item.mo_r, item.ye_r])}}
                </template>
                <template v-slot:item.custom="{ item }">
                 {{tirarBarra([item.fh_c,item.fc_c, item.da_c, item.mo_c, item.ye_c])}}
                </template>
                <template v-slot:item.history="{ item }">
                  <v-btn  color="success" disabled small icon @click="history(item.id)"><v-icon>mdi-cube</v-icon></v-btn>
                </template>
                <template v-slot:item.certificate="{ item }">
                  <v-btn  :disabled="(item.certificate == null)?true:false" color="success" small icon target="_blank" link :href="$store.state.path2 + item.certificate"><v-icon>mdi-file-cad</v-icon></v-btn>
                </template> -->
              </v-data-table>
            </v-card>
         
         
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },

  data(){
      return{
        search:null,
           headers: [
          {
            text: 'CARD NO',
            align: 'start',
            value: 'card_no',
             class: "info white--text",
            width:120,
            
          },
          { text: 'PACKAGE',  value: 'package',class: "info white--text", width:120,},
          { text: 'DATE', align: 'center', value: 'date', class: "info white--text", width:120, },
          { text: 'FH',  value: 'fh' ,class: "info white--text", width:100,},
          { text: 'FC', value: 'fc',class: "info white--text", width:100, },
          { text: 'NEXT DATE', value: 'nex_date' ,class: "info white--text", width:120,},
          { text: 'FH', align: 'next_fh', value: 'upload',class: "info white--text", width:100,},
          { text: 'FC', value: 'next_fc' ,class: "info white--text", width:100,},
          { text: 'INTERVAL', value: 'interval',class: "info white--text", width:100, },
          { text: 'MAN HOURS', value: 'man_hours',class: "info white--text", width:100, },
          
        ],

        tcLastDone:[],

         
      }
  
  },


  methods:{

    nextDate(data, dias){

      
      if(!data|| !dias){
        return '';
      }

      var date = new Date(data);
      date.setDate(date.getDate() + parseInt(dias));

      var mes = date.getMonth()+1;
      let dataFinal =  date.getFullYear() + "-" + ((mes.toString().length<2)?'0'+mes:mes) + '-' + ((date.getDate().toString().length<2)?'0'+date.getDate():date.getDate());


      return dataFinal||'';

    },
    //Este metodo é executado antes de se eliminar um registo.
    remove(index, item) {
      let dis = this;
      const Swal = require("sweetalert2");

      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          dis.delete(index, item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "This datet is is safe :)", "error");
        }
      });
    },
    editar(item){
        this.$store.state.editing = true;
        this.$store.state.editingItem = item;
        this.$store.state.to="/acInformationCadastro";
        this.$router.push('acInformationCadastro');
    }
  },
  mounted(){
    this.$store.dispatch('permission');
    this.$store.dispatch("getTaskCard");

    let dis = this;
    dis.$store.dispatch('loading');
    const axios = require('axios');
    let added = [];
    axios.get(this.$store.state.path+"task_card_last_done").then(result=>{

      result.data.data.forEach(element => {

        console.log(added.indexOf(element.card_no))
        if(added.indexOf(element.card_no)==-1){
          dis.tcLastDone.push(element);
          added.push(element.card_no)
        }
      
      });
       console.log(added)

      

    dis.$store.dispatch('nloading');

    }).then(()=>{
    dis.$store.dispatch('nloading');

    })

    
  }

}
</script>

<style>

</style>