<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" >

        <v-card-title class="px-0" primary-title>
            ADD NEW PART
        </v-card-title>
        <v-divider></v-divider>


        <v-row>
                <v-col cols="6">
                    <v-autocomplete label="FLEET" v-model="dados.model" dense outlined :items="$store.state.aircraftType" item-text="designation"
                    item-value="designation">
                </v-autocomplete>
               </v-col>
               
               
                
                
                <v-col cols="6">
                    <v-autocomplete label="ATA CHAPTER" dense v-model="dados.ata_chapter" outlined :items="$store.state.ata" item-value="chapter" item-text="chapter">
                    <template v-slot:item="{item}">
                        <v-list-item-content >
                            {{item.chapter}} - {{item.designation}}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
                </v-col>

                <v-col :cols="6">
                      <v-text-field
                            label="Keywords"
                            dense
                            outlined
                            v-model="dados.keywords"
                        ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-autocomplete label="Part Type" v-model="dados.part_type" dense outlined :items="$store.state.partType" item-text="part_type"
                    item-value="part_type">
                </v-autocomplete>
               </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="Part Description"
                            dense
                            outlined
                            v-model="dados.part_description"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="Part Level"
                            dense
                            outlined
                            v-model="dados.part_level"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="NHA"
                            dense
                            outlined
                            v-model="dados.nha"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="Quantity"
                            dense
                            outlined
                            v-model="dados.quantity"
                        ></v-text-field>
                </v-col>
                <v-col :cols="edit?3:4">
                      <v-text-field
                            label="Price"
                            dense
                            outlined
                            v-model="dados.price"
                        ></v-text-field>
                </v-col>
              

              <v-col :cols="edit?3:4">
                  <v-text-field
                  label="Price Expire Date"
                  type="date"
                  dense
                  outlined
                  v-model="dados.price_expire_date"
              ></v-text-field>
                </v-col>
                
                <v-col :cols="edit?3:4">
                      <v-text-field
                            label="MTBR"
                            dense
                            outlined
                            v-model="dados.mtbr"
                        ></v-text-field>
                </v-col>
                
                
                 
                 
               


               <v-row class="justify-end">
                    <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                    <v-btn color="primary" @click="save" :disabled=$store.state.permission elevation="0" class="ml-2">Save</v-btn>
                </v-row>
               

           
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{

          dueDate:false,
          mel:false,
          thisLogIsForMaintenance:'',
          edit:false,

          dados:{
                model:'',
                part_type:'',
                ata_chapter:'',
                part_description:'',
                keywords:'',
                part_level:'',	
                nha:'',
                quantity:'',
                price:'',
                price_expire_date:'',
                mtbr:'',
                }
      }
  },
  methods:{
      clear(){
          this.dados={
                model:'',
                part_type:'',
                ata_chapter:'',
                part_description:'',
                keywords:'',
                part_level:'',	
                nha:'',
                quantity:'',
                price:'',
                price_expire_date:'',
                mtbr:''
               
                }
      },
    // Funcao para salver users na bd
    save() {


        let store = this.$store;
      store.dispatch("loading")
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "parts";
      let dis = this;

      url += this.edit ? ("/"+this.dados.id.toString()) + "?_method=PATCH" : "";

       const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization':'Bearer '+window.sessionStorage.getItem("token")
            }
        }

        var form_data = new FormData();

        for ( var key in this.dados ) {
            form_data.append(key, this.dados[key]||'');
        }
        form_data.append('file',this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating PARTS",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch('getParts');
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New PARTS Updated successfuly. "
                  : "PARTS added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New PARTS edited successfuly. "
                  : "PARTS Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
  mounted(){
    this.$store.dispatch('permission');
    this.$store.dispatch('getAcType');
      this.$store.dispatch('getAta');
      this.$store.dispatch('getPartType');

       if(this.$store.state.editing){

        this.dados = this.$store.state.editingItem;
        this.edit = true;

      }
  }
  

}
</script>

<style>

</style>