<template>
    <div>
    <cabeca></cabeca>

     <v-container grid-list-xs>
         
         <v-card
            class="mx-auto pa-2 px-4 mb-10"
            outlined
            color="white"
        >
        <v-card-title class="px-0" primary-title>
            CABIN DEFECT
        </v-card-title>

        <v-divider></v-divider>
        <v-row class="mt-5">
            <v-col class="pa-0 px-1" cols="3">
                <v-autocomplete attach v-model="part.aircraft_type" item-value="designation" item-text="designation" :items="$store.state.aircraftType"  dense outlined label="*Aircraft Type">
                    <template v-slot:item="{ item }" >                                                                       
                        <v-list-item-content @click="getAircraftConfig(item.designation)">
                            {{item.designation}}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-col>

               <v-col class="pa-0 px-1" cols="3">
                <v-autocomplete attach v-model="part.reg_no"  item-value="reg_no" item-text="reg_no" :items="$store.state.aircraftConfig"  dense outlined label="*REG NO">
                    <template v-slot:item="{ item }" >
                        <v-list-item-content>
                            {{item.reg_no}}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
               </v-col>
            </v-row>
            <v-row class="">
            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field  v-model="part.tsn"  label="FIGHT NUMBER"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0 mt-0 mx-1" style="max-width:180px;">
                        <v-text-field  v-model="part.date"   label="*DATE" type="date" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field  v-model="part.csn" label="STATION"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field   v-model="part.tso" label="CHECKED BY"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field  v-model="part.cso" label="STATION"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0 mt-0 mx-1" style="max-width:180px;">
                        <v-text-field  v-model="part.date2"   label="*DATE" type="date" dense outlined   ></v-text-field>
                    </v-col>
                </v-row>
            
            <v-row class="text-center justify-center info white--text align-center mb-2">
               <p class="ma-0 py-1"> CABIN DEFECT LOG</p>
            </v-row>

        <v-row cols="3" class="mt-5">
                    <v-col cols="6" class="pa-0 ma-0 mt-0 mx-1" style="max-width:110px;">
                         
                         <v-checkbox label="New"  class="ma-0" v-model="part.new" ></v-checkbox>
                     </v-col>
                    <v-col cols="6" class="pa-0 ma-0 mt-0 mx-1" style="max-width:110px;">
                         
                        <v-checkbox label="O/H"  class="ma-0" v-model="part.o_h" ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="pa-0 ma-0 mt-0 mx-1" style="max-width:110px;">
                         
                         <v-checkbox label="Repair"  class="ma-0" v-model="part.repair" ></v-checkbox>
                     </v-col>
                    
                    <v-col cols="3" class="pa-0 ma-0 mt-2 mx-1" style="max-width:180px;">
                        <v-text-field  v-model="part.shelf_life_date"   label="*Shelf Life Date" type="date" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" cols="3" style="max-width:220px;">
                   <v-text-field
                       label="*Certification Information"
                       v-model="part.certification"
                       dense outlined
                   ></v-text-field>
                    </v-col> 
                    <v-col cols="3" class="pa-0 ma-0 mt-2 mx-1" style="max-width:180px;">
                        <v-text-field  v-model="part.certification_date"  label="*Certification Date" type="date" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" cols="3" style="max-width:180px;">
                        <v-text-field  v-model="part.tag_no"  label="TAG Nº"  dense outlined   ></v-text-field>
                    </v-col>
                    
                     
                    
                    
               
                </v-row>
                
                

                
                <v-row class="mt-5">
               <v-row class="pa-0 ma-0 mt-0 mx-1">
                    <v-textarea
                    class="mx-2 my-0"
                    label="*Description"
                    rows="3"
                    outlined
                    v-model="part.description"
                    ></v-textarea>
                </v-row>
                
                <v-row class="pa-0 ma-0 mt-0 mx-1">
                    <v-textarea
                    class="mx-2 my-0"
                    label="*Description"
                    rows="3"
                    outlined
                    v-model="part.description"
                    ></v-textarea>
                </v-row>
               
                    
            </v-row>
            <v-row cols="3" class="mt-5">
                <v-col cols="6" class="pa-0 ma-0 mt-0 mx-1" style="max-width:210px;">
                        EMERGENCY ITEM
                        <v-checkbox label="No"  class="ma-0" v-model="part.emerg_itm" ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="pa-0 ma-0 mt-0 mx-1" style="max-width:110px;">
                        REPORTED BY
                        <v-checkbox label="MNT"  class="ma-0" v-model="part.mnt" ></v-checkbox>
                        <v-checkbox label="CREW"  class="ma-0" v-model="part.crew" ></v-checkbox> 
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" cols="3" style="max-width:180px;">
                        <v-text-field  v-model="part.tag_no"  label="TAG Nº"  dense outlined   ></v-text-field>
                    </v-col>
            </v-row>
            <v-row cols="3" class="mt-5">
            
                <v-col class="pa-0 ma-0 mt-2 mx-1" cols="3" style="max-width:180px;">
                    <v-text-field  v-model="part.tag_no"  label="TAG Nº"  dense outlined   ></v-text-field>
                </v-col>
                <v-col class="pa-0 ma-0 mt-2 mx-1" cols="3" style="max-width:180px;">
                    <v-text-field  v-model="part.tag_no"  label="TAG Nº"  dense outlined   ></v-text-field>
                </v-col>
            </v-row>
            <v-row cols="3" class="mt-5">
            
            <v-col class="pa-0 ma-0 mt-2 mx-1" cols="3" style="max-width:180px;">
                <v-text-field  v-model="part.tag_no"  label="TAG Nº"  dense outlined   ></v-text-field>
            </v-col>
            <v-col class="pa-0 ma-0 mt-2 mx-1" cols="3" style="max-width:180px;">
                <v-text-field  v-model="part.tag_no"  label="TAG Nº"  dense outlined   ></v-text-field>
            </v-col>
        </v-row>
        <!-----------------------NEW FORM-------------------------------> 
        
            
            
           <v-col>
            
            

            
<!-- 1 ------------------------------------------------------------------------------------------------------------ -->
               


<!-- 1 ------------------------------------------------------------------------------------------------------------ -->
               

<!-- 1 ------------------------------------------------------------------------------------------------------------ -->
               









<!-- ################################################################################################################################################# -->
                  <v-row>
                        <v-file-input
                            label="File input"
                            id="file-image"
                            outlined
                            dense
                            v-model="part.file"
                            prepend-icon=""                       
                            prepend-inner-icon="mdi-file"
                        ></v-file-input>

                        <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                        <v-btn color="primary" @click="save" elevation="0" class="ml-2">Save</v-btn>
                  </v-row>
           </v-col>
            
      
        </v-card>


     </v-container> 

    </div>
</template>

<script>
import cabeca from "@/components/cabeca";

export default {
  components: {
    cabeca,
  },
  methods:{
     
      f_update(val){
        this.part.airframe_time=val;
        
      },
      getAircraftConfig(val){
      this.$store.dispatch("getAircraftConfig",val);
    },

      clear(){
          this.part = {
            file:'',
            tag_no:'',
            new:'',
            o_h:'',
            repair:'',
            insp:'',
            test:'',
            robbed_from:'',
            robbed_from_set:'',
            shelf_life_date:'',
            certification:'',
            certification_date:'',
            description:'',
            part_no:'',
            serial_no:'',
            part_man:'',
            tsn:'',
            csn:'',
            tso:'',
            cso:'',
            bin_loc:'',

            ////////////////////
           
            position:'',
            atl_no:'',
            rmvd_part_no:'',
            rmvd_serial_no:'',
            reason:'',
            name_sgn:'',
            emp_no:'',
            
            rmvd_date:'',
              
            }
          
          
           this.part2 ={
                position:'',
                
                atl_no:'',
                rmvd_part_no:'',
                rmvd_serial_no:'',
                reason:'',
                name_sgn:'',
                emp_no:'',
                airframe_time:'',
                cycles:'',
                rmvd_date:'',

              
                }
      },

      getFile(file){
        //const file = e.target;
        //this.$store.dispatch('loading');

      

        //let file_ =file.target.files[0];

        
        //const file = e.target.files[0];
        console.log(file);
      },
      
    // Funcao para salver users na bd
    save() {

        let store = this.$store;
        store.dispatch("loading")
        let axios = require("axios");
        const Swal = require("sweetalert2");

        let url = this.$store.state.path + "cabineDefectLog";
        let dis = this;
        


       
            url += this.edit ? ('/'+this.part.id.toString()) + "?_method=PATCH" : "";

        

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
 
    var form_data = new FormData();

    for ( var key in this.part ) {
        form_data.append(key, this.part[key]);
    }
     //form_data.append('file',this.file);

      console.info("Fiormulario",form_data);

      if (dis.part.description) {
      axios
        .post(url, form_data, config)
        .then(function(response) {
            store.dispatch("nloading")
          if (response.status == 200){
          store.dispatch("nloading")
              
              if (!dis.edit) {
                store.state.cabine.push(response.data);
                store.state.cabine.push(response.data);
               
  
                store.dispatch("getCabine");
                if( dis.part.description  ){
                    //this.uploadStatus = { type: 'success', message: 'File uploaded successfully!' };
                    //console.log('File uploaded successfully', response.data);
                Swal.fire(
                  "Success!",
                  dis.edit
                    ? "New part Updated successfuly. Part"
                    : "part Added successfuly. Part",
                  "success"
                );};
              } else {
                   /* store.dispatch('getAircraftInformation'); */
                  //if(  dis.part.block_time  ){
                store.state.cabine.push(response.data);
              store.state.cabine.push(response.data);
             /*  store.state.acInformation = []; */

              store.dispatch("getCabine");
                Swal.fire(
                  "Success!",
                  dis.edit
                    ? "New part edited successfuly. Part"
                    : "part Updated successfuly. Part",
                  "success"
                );
            }
            
              } //}
              
  
              /*if(dis.part.reason){
                  dis.save2();
              }*/

            if(!dis.part.reg_no  ||  dis.part.reg_no){
               dis.clear();
                return null;
           }
                    
            }
        )
        .catch(function(error) {
            store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });}else{
            /*if(dis.part.reason){
                  dis.save2();
              } */

            if(!dis.part.reg_no  ||  dis.part.reg_no){
               dis.clear();
                return null;
           }
        }
    },
    
    save2() {

        let store = this.$store;
        store.dispatch("loading")
        let axios = require("axios");
        const Swal = require("sweetalert2");

        let url = this.$store.state.path + "partRemoved";
        let dis = this;

        url += this.edit ? ('/'+this.part.id.toString()) + "?_method=PATCH" : "";

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        

        
        
        

    var form_data = new FormData();

    for ( var key in this.part ) {
        form_data.append(key, this.part[key]);
    }
     form_data.append('file',this.part.file);


   
      axios
        .post(url, form_data, config)
        .then(function(response) {
            store.dispatch("nloading")
          if (response.status == 200){
            store.dispatch("nloading")
              
            if (!dis.edit) {
              store.state.partRemoved.push(response.data);
              store.state.partRemoved.push(response.data);
             /*  store.state.acInformation = []; */

              store.dispatch("getPartRemoved");

              Swal.fire(
                "Success!",
                dis.edit
                  ? "Part Removed Updated successfuly. "
                  : "Part Removed Added successfuly.",
                "success"
              );
            } else {
                 /* store.dispatch('getAircraftInformation'); */

              Swal.fire(
                "Success!",
                dis.edit
                  ? "Part Removed edited successfuly. "
                  : "Part Removed Updated successfuly.",
                "success"
              );
            }
          }
            
          else {
              
            Swal.fire(
                "Success!",
                dis.edit
                  ? "Part Removed edited successfuly. "
                  : "Part Removed Updated successfuly.",
                "success"
              );

/* ############################################################################################ */

          }
        })
        .catch(function(error) {
            store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save the Part Removed. " + error, "error");
          console.info(error);
        });
    },

    
  },
  data(){
      return {
          edit:false,
          file:'',
          part:{
            file:'',
            reg_no:'',
            tag_no:'',
            new:'',
            o_h:'',
            repair:'',
            insp:'',
            test:'',
            robbed_from:'',
            robbed_from_set:'',
            shelf_life_date:'',
            certification:'',
            certification_date:'',
            description:'',
            part_no:'',
            serial_no:'',
            aircraft_type:'',
            part_man:'',
            tsn:'',
            csn:'',
            tso:'',
            cso:'',
            bin_loc:'',
            ////////////////////
           
            position:'',
            atl_no:'',
            rmvd_part_no:'',
            rmvd_serial_no:'',
            reason:'',
            name_sgn:'',
            emp_no:'',
            
            rmvd_date:'',

          
          },
          part2:{
            file:'',
            reg_no:'',
            
            aircraft_type:'',
            position:'',
            atl_no:'',
            rmvd_part_no:'',
            rmvd_serial_no:'',
            reason:'',
            name_sgn:'',
            emp_no:'',
            airframe_time:'',
            cycles:'',
            rmvd_date:'',
          }
      }
  },

  mounted(){
      
    this.$store.dispatch('permission');
      this.$store.dispatch("getAircraftInformation");
      this.$store.dispatch("getParts");
      this.$store.dispatch("getAcType");
      

       if(this.$store.state.editing){
           let log = this.$store.state.editingItem;

        if(log.description){
            
            this.part = {

                id: log.id,
                reg_no:log.reg_no,
                tag_no:log.tag_no,
                aircraft_type:(log.aircraft_type!="null")?log.aircraft_type:null,
                new:(log.new!="null")?log.new:false,
                o_h:(log.o_h!="null")?log.o_h:false,
                repair:(log.repair!="null")?log.repair:false,
                insp:(log.insp!="null")?log.insp:false,
                test:(log.test!="null")?log.test:false,
                robbed_from:(log.robbed_from!="null")?log.robbed_from:null,
                robbed_from_set:(log.robbed_from_set!="null")?log.robbed_from_set:false,
                shelf_life_date:(log.shelf_life_date!="null")?log.shelf_life_date:null,
                certification:(log.certification!="null")?log.certification:null,
                certification_date:(log.certification_date!="null")?log.certification_date:null,
                description:(log.description!="null")?log.description:null,
                part_no:(log.part_no!="null")?log.part_no:null,
                serial_no:(log.serial_no!="null")?log.serial_no:null,
                
                part_man:(log.part_man!="null")?log.part_man:null,
                tsn:(log.tsn!="null")?log.tsn:null,
                csn:(log.csn!="null")?log.csn:null,
                tso:(log.tso!="null")?log.tso:null,
                cso:(log.cso!="null")?log.cso:null,
                bin_loc:(log.bin_loc!="null")?log.bin_loc:null,
                file:log.file

            };

            
        }
        else{
            this.part = {
                id:log.id,
                reg_no:log.reg_no,
                aircraft_type:(log.aircraft_type!="null")?log.aircraft_type:null,
                position:log.position,
                atl_no:log.atl_no,
                rmvd_part_no:log.rmvd_part_no,
                rmvd_serial_no:log.rmvd_serial_no,
                reason:log.reason,
                name_sgn:log.name_sgn,
                emp_no:log.emp_no,
                airframe_time:log.airframe_time,
                cycles:log.cycles,
                rmvd_date:log.rmvd_date, 
            };
            
        }

        this.edit = true;
        }
  },
 
  


}
</script>

<style>

</style>