<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" >

        <v-card-title class="px-0" primary-title>
            ADD NEW APU LOG
        </v-card-title>
        <v-divider></v-divider>


        <v-row>
                <v-col cols="6">
                    <v-autocomplete label="Reg Number" v-model="dados.reg_no" dense outlined :items="$store.state.apu" item-text="reg_no"
                    item-value="reg_no">
                </v-autocomplete>
               </v-col>
               
               
                
                
                

               <v-col :cols="6">
                  <v-text-field
                  label="Date"
                  type="date"
                  dense
                  outlined
                  v-model="dados.date"
              ></v-text-field>
                </v-col>
                
               
                <v-col :cols="6">
                      <v-text-field
                            label="Hour"
                            dense
                            outlined
                            v-model="dados.hour"
                        ></v-text-field>
                </v-col>
               
                <v-col :cols="6">
                      <v-text-field
                            label="Minutes"
                            dense
                            outlined
                            v-model="dados.minutes"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="Cycles"
                            dense
                            outlined
                            v-model="dados.cycles"
                        ></v-text-field>
                </v-col>
                
               
              

              
                
               
                
                
                 
                 
               
               
                <v-col cols="6">
                    <v-file-input
                        dense outlined
                        label="File"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        v-model="file"
                    ></v-file-input>
                </v-col>
               <v-row class="justify-end">
                    <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                    <v-btn color="primary" @click="save" :disabled=$store.state.permission elevation="0" class="ml-2">Save</v-btn>
                </v-row>
               

           
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{

          dueDate:false,
          mel:false,
          thisLogIsForMaintenance:'',
          edit:false,

          dados:{
                reg_no:'',
                hour:'',
                minutes:'',
                cycles:'',
                date:'',
                vpn:'',
                sn:'',
                status:'',
                hours_used:'',
                cycles_used:'',
                file:''
                }
      }
  },
  methods:{
      clear(){
          this.dados={
            reg_no:'',
            hour:'',
            minutes:'',
            cycles:'',
            date:'',
            vpn:'',
            sn:'',
            status:'',
            hours_used:'',
            cycles_used:'',
            file:''
               
                }
      },
    // Funcao para salver users na bd
    save() {


        let store = this.$store;
      store.dispatch("loading")
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "apuLog";
      let dis = this;

      url += this.edit ? ("/"+this.dados.id.toString()) + "?_method=PATCH" : "";

       const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization':'Bearer '+window.sessionStorage.getItem("token")
            }
        }

        var form_data = new FormData();

        for ( var key in this.dados ) {
            form_data.append(key, this.dados[key]||'');
        }
        form_data.append('file',this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating APU LOG",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch('getApuLog');
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New APU Updated successfuly. "
                  : "PARTS added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New APU edited successfuly. "
                  : "APU Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
  mounted(){
    this.$store.dispatch('permission');
    this.$store.dispatch('getAcType');
      this.$store.dispatch('getAta');
      this.$store.dispatch('getPartType');
      this.$store.dispatch('getApu')

       if(this.$store.state.editing){

        this.dados = this.$store.state.editingItem;
        this.edit = true;

      }
  }
  

}
</script>

<style>

</style>