<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" >

        <v-card-title class="px-0" primary-title>
            SERVICE BULLETIN CLOSED
        </v-card-title>
        


        <v-row>
                <v-col cols="6">
                    <v-autocomplete label="AC" v-model="dados.reg_no" dense outlined :items="$store.state.acInformation" item-text="reg_no"
                    item-value="reg_no">
                    <template v-slot:item="{item}">
                    <v-list-item-content @click="getTaskRequest(item.reg_no)">
                            {{item.reg_no}}
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                
               </v-col>
               
               
                
                
                <v-col cols="6">
                    <v-autocomplete label="SB Nº" dense v-model="dados.sb_no" outlined :items="$store.state.taskSbRequest" item-value="sb_no" item-text="sb_no">
                    <template v-slot:item="{item}">
                        <v-list-item-content >
                            {{item.sb_no}}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
                </v-col>

                <v-col :cols="6">
                      <v-text-field
                            label="DESCRIPTION"
                            dense
                            outlined
                            v-model="dados.sb_description"
                        ></v-text-field>
                </v-col>
                
                
                
                <v-col :cols="6">
                      <v-text-field
                            label="COMPLETE DATE"
                            type="date"
                            dense
                            outlined
                            v-model="dados.complete_date"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="AC Hours"
                            dense
                            outlined
                            v-model="dados.ac_time"
                        ></v-text-field>
                </v-col>
                <v-col :cols="6">
                      <v-text-field
                            label="AC Cycles"
                            dense
                            outlined
                            v-model="dados.ac_cycles"
                        ></v-text-field>
                </v-col>
                
                <v-col cols="6">
                    <v-file-input
                        dense outlined
                        label="File"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        v-model="close_file"
                    ></v-file-input>
                </v-col>
                
                
              
                
                
                
                
                 
                 
               


               <v-row class="justify-end">
                    <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                    <v-btn color="primary" @click="save" elevation="0" class="ml-2">Save</v-btn>
                </v-row>
               

           
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{

          dueDate:false,
          mel:false,
          thisLogIsForMaintenance:'',
          edit:false,
          close_file:'',
          dados:{
            reg_no:'',sb_no:'',
            
             sb_description:'',  close_date:'',
            complete_date:'',due_date:'',ac_time:'',ac_cycles:''
                }
      }
  },
  methods:{
      clear(){
          this.dados={
            reg_no:'',sb_no:'',
            
             sb_description:'',  close_date:'',
            complete_date:'',due_date:'',ac_time:'',ac_cycles:''
               
                }
      },
    // Funcao para salver users na bd
    save() {


        let store = this.$store;
      store.dispatch("loading")
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "serviceBulletinClose";
      let dis = this;

      url += this.edit ? ("/"+this.dados.id.toString()) + "?_method=PATCH" : "";

       const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization':'Bearer '+window.sessionStorage.getItem("token")
            }
        }

        var form_data = new FormData();

        for ( var key in this.dados ) {
            form_data.append(key, this.dados[key]||'');
        }
        form_data.append('file',this.close_file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating serviceBulletin",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch('getserviceBulletin');
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New serviceBulletin Updated successfuly. "
                  : "serviceBulletin added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New serviceBulletin edited successfuly. "
                  : "serviceBulletin Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },


    getTaskRequest(val){
      this.$store.dispatch("getTaskSbRequest",val);
    },
  },
  mounted(){
    this.$store.dispatch("getAircraftInformation");
      this.$store.dispatch('getAta');
      this.$store.dispatch('getPartType');

       if(this.$store.state.editing){

        this.dados = this.$store.state.editingItem;
        this.edit = true;

      }

  }
  

}
</script>

<style>

</style>