<template>
     <div>
    <cabeca></cabeca>

     <v-container grid-list-xs>
         <v-card
            class="mx-auto pa-2 px-10 mb-10"
            outlined
            color="white"
        >

        <v-card-title class="px-0" primary-title>
            NEW TASK CARD ENTRY
        </v-card-title>
            <v-divider></v-divider>

            <v-row>
                <h3 class="ml-2">INTERNAL ID</h3>
            </v-row>

            <v-divider></v-divider>
            <h3 class="pa-0 ma-0 mt-3">TC TYPE</h3>
            <v-row >
                <v-radio-group v-model="dados.tc_type" row>
                    <v-radio
                    value="AC"
                    :label="`AC`"
                    ></v-radio>
                    <v-radio
                    value="ENG"
                    :label="`ENG`"
                    ></v-radio>
                    <v-radio
                    value="AP"
                    :label="`AP`"
                    ></v-radio>
            </v-radio-group>
            </v-row>
            <v-divider class="mt-1"></v-divider>

            <v-row>
                <v-col cols="6" class="py-0 my-0 mt-3">
                    <v-autocomplete
                        v-model="dados.ac_model"
                        :items="$store.state.aircraftType"
                        item-text="designation"
                        item-value="designation"
                        outlined
                        dense
                        chips
                        small-chips
                        label="*A/C Model"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-0 my-0 mt-3">
                     <v-autocomplete
                        v-model="dados.ata"
                        :items="$store.state.ata"
                        outlined
                        item-text="chapter"
                        item-value="chapter"
                        dense
                        chips
                        small-chips
                        label="ATA"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="py-0 my-0">
                    <v-text-field
                        label="Card No"
                        outlined
                        dense
                        v-model="dados.card_no"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0 my-0">
                    <v-text-field
                        label="Airline Card No"
                        outlined
                        dense
                        v-model="dados.airline_card_no"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-divider></v-divider>

            <h3>REV CONTROL</h3>

            <v-row>
                <v-col cols="6">
                    <v-checkbox
                    v-model="dados.revision_controlled_task_card"
                    :label="`Revision Controlled Task Card: ${dados.revision_controlled_task_card?'YES':'NO'}`"
                ></v-checkbox>
                </v-col>

               <v-col cols="6">
                    <v-text-field
                        label="Revision Date"
                        type="Date"
                        dense
                        outlined
                        hint="Revision Date"
                        persistent-hint
                        v-model="dados.rev_date"
                    ></v-text-field>
               </v-col>

            </v-row>
            <v-divider></v-divider>
             <h3>Man Hours</h3>
              <v-row>
                <v-col cols="4">
                   <v-text-field
                        label="Access"
                        dense
                        outlined
                        persistent-hint
                        v-model="dados.access"
                    ></v-text-field>
                </v-col>

               <v-col cols="4">
                    <v-text-field
                        label="Task"
                        dense
                        outlined
                        persistent-hint
                        v-model="dados.task"
                    ></v-text-field>
               </v-col>
               <v-col cols="4">
                    <v-text-field
                        label="Estimate"
                        dense
                        outlined
                        persistent-hint
                        v-model="dados.estimate"
                    ></v-text-field>
               </v-col>

            </v-row>

             <v-divider></v-divider>

            <h3>LOCATION TYPE</h3>

            <v-row>
            <v-radio-group v-model="dados.location_type" row>
                    <v-radio
                    value="Line"
                    :label="`Line`"
                    ></v-radio>
                    <v-radio
                    value="Base"
                    :label="`Base`"
                    ></v-radio>
                    <v-radio
                    value="MRO"
                    :label="`MRO`"
                    ></v-radio>
            </v-radio-group>
            </v-row>
            <v-divider></v-divider>

            <v-row>
                <v-col cols="6">
                    <v-text-field
                        label="*Title"
                        dense
                        outlined
                        persistent-hint
                        v-model="dados.title"
                    ></v-text-field>
               </v-col>
            </v-row>

             <v-divider></v-divider>
            <h3>*Applicability</h3>
            <v-row>
                <v-col cols="6" class="py-0">
                    <v-checkbox
                    v-model="dados.for_all_airplane_or_engine"
                    :label="`For All Airplanes or Engines: ${dados.for_all_airplane_or_engine?'YES':'NO'}`"
                    ></v-checkbox>
                </v-col>
            </v-row>

                <v-divider></v-divider>
                <h3>Intervals</h3>

            <v-row class="pa-0 ma-0">
                <v-col cols="1" class="py-0">
                    <p class="pa-0 mt-2">FH</p>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Threshold" v-model="dados.fh_t" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Repeat" v-model="dados.fh_r" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Custom" v-model="dados.fh_c" dense outlined  persistent-hint ></v-text-field>
               </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
                <v-col cols="1" class="py-0"> 
                    <p class="pa-0 mt-2" vmd>FC</p>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Threshold" v-model="dados.fc_t" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Repeat" v-model="dados.fc_r" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Custom" v-model="dados.fc_c" dense outlined  persistent-hint ></v-text-field>
               </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
                <v-col cols="1" class="py-0"> 
                    <p class="pa-0 mt-2">Days</p>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Threshold" v-model="dados.da_t" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Repeat" v-model="dados.da_r" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Custom" v-model="dados.da_c" dense outlined  persistent-hint ></v-text-field>
               </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
                <v-col cols="1" class="py-0"> 
                    <p class="pa-0 mt-2">Months</p>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Threshold" v-model="dados.mo_t" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Repeat" v-model="dados.mo_r" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Custom" v-model="dados.mo_c" dense outlined  persistent-hint ></v-text-field>
               </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
                <v-col cols="1" class="py-0"> 
                    <p class="pa-0 mt-2">Years</p>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Threshold" v-model="dados.ye_t" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Repeat" v-model="dados.ye_r" dense outlined persistent-hint ></v-text-field>
               </v-col>
                <v-col cols="3" class="py-0">
                    <v-text-field label="Custom" v-model="dados.ye_c" dense outlined  persistent-hint ></v-text-field>
               </v-col>
            </v-row>

            <v-divider></v-divider>
            <h3>*Notes</h3>

             <v-row class="pa-0 ma-0">
                 <v-textarea
                    label="Notes"
                    outlined
                    v-model="dados.notes"
                    ></v-textarea>
            </v-row>

            <v-row class="px-3">
                <v-file-input
                    label="Card File Upload"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    outlined
                    v-model="file"
                    dense
                ></v-file-input>
            </v-row>

            <v-row class="justify-end">
                <v-btn color="info" @click="clear()" elevation="0" class="ml-2">Cancel</v-btn>
                <v-btn color="primary" @click="save()" :disabled=$store.state.permission elevation="0" class="ml-2">Save</v-btn>
            </v-row>


         </v-card>
  
     </v-container> 

    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{
        file:'',
          dados:{
               internal_id: '',
                tc_type: '',
                ac_model: '',
                card_no: '',
                airline_card_no: '',
                ata: '',
                revision_controlled_task_card: '',
                rev_date: '',
                access: '',
                task: '',
                estimate: '',
                location_type: '',
                title: '',
                for_all_airplane_or_engine: '',
                fh_t: '',
                fh_r: '',
                fh_c: '',
                fc_t: '',
                fc_r: '',
                fc_c: '',
                da_t: '',
                da_r: '',
                da_c: '',
                mo_t: '',
                mo_r: '',
                mo_c: '',
                ye_t: '',
                ye_r: '',
                ye_c: '',
                notes: '',
                card_file_upload: '',
          }
      }
  },
  methods:{

      clear(){
          this.dados={
               internal_id: '',
                tc_type: '',
                ac_model: '',
                card_no: '',
                airline_card_no: '',
                ata: '',
                revision_controlled_task_card: '',
                rev_date: '',
                access: '',
                task: '',
                estimate: '',
                location_type: '',
                title: '',
                for_all_airplane_or_engine: '',
                fh_t: '',
                fh_r: '',
                fh_c: '',
                fc_t: '',
                fc_r: '',
                fc_c: '',
                da_t: '',
                da_r: '',
                da_c: '',
                mo_t: '',
                mo_r: '',
                mo_c: '',
                ye_t: '',
                ye_r: '',
                ye_c: '',
                notes: '',
                card_file_upload: '',
          }
      },
      save() {
    
    
      let store = this.$store;
       store.dispatch("loading")

      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "taskCard";
      let dis = this;

      url += this.edit ? this.dados.id.toString() + "?_method=PATCH" : "";

      const config = {
        headers: {
            'content-type': 'multipart/form-data',
           
            'Authorization':'Bearer '+window.sessionStorage.getItem("token")
            
        }
    }

    var form_data = new FormData();

    for ( var key in this.dados ) {
        form_data.append(key, this.dados[key]);
    }
     form_data.append('card_file_upload',this.file);

     

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating Task Card",
              "error"
            );
          else {
            if (!dis.edit) {
              store.state.taskCard = response.data.data;
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New Task Card added successfuly. "
                  : "Task Card Updated successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New Task Card edited successfuly. "
                  : "Task Card Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
  mounted(){
      this.$store.dispatch('getAcType');
      this.$store.dispatch('permission');
      this.$store.dispatch('getAta');
  }

}
</script>

<style>

</style>