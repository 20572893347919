<template>
  <div>
        <cabeca></cabeca>
        <partsFilter :dialog="this.$store.state.dialog"></partsFilter>

         <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="pa-0" primary-title>
          <span>REQUISITION SEARCH</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-account-search"
            dense
            outlined
            label="Search"
            class="ma-0 pa-0"
          ></v-text-field>
         
          <v-row class="justify-end">
             <v-btn color="info" class="mr-2 mt-1" depressed small route to="/newParts">Add New Part </v-btn>
             <v-btn color="primary" class="mr-10" icon @click="$store.state.dialog = true"> <v-icon>mdi-filter-menu</v-icon></v-btn>
            <download-excel
              header="Part List"
              name="partsList"
              :data="desserts"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn>
            <v-btn color="info" icon><v-icon>mdi-printer</v-icon></v-btn>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

      

            <v-card flat height="350">
              <v-data-table
                height="300"
                :search="search"
                fixed-header
                dense
                :headers="headers"
                :items="$store.state.parts"
                :items-per-page="10"
              >
              
                <template v-slot:item.options="{ item, index }">
                  <v-btn
                    @click="editar(item)"
                    :disabled=$store.state.permission
                    small
                    icon
                    class="mx-2"
                    color="orange"
                    ><v-icon>mdi-clipboard-edit</v-icon></v-btn
                  >
                  <v-btn
                    @click="remove(index, item)"
                    :disabled=$store.state.permission
                    small
                    icon
                    class="mx-2"
                    color="red white--text"
                    ><v-icon>mdi-trash-can</v-icon></v-btn
                  >
                </template>
                
                <template v-slot:item.created_at="{ item }">
                    
                    {{ item.created_at.toString().split(" ")[0] }}
                </template>
                <template v-slot:item.due_date="{ item }">
                    
                    {{ getDate(item.data_discovery, (item.due_date?item.due_date:item.mel).toString().split(" ")[0]) }}
                    
                </template>

                 <template v-slot:item.op_impact="{ item }">
                  {{(item.op_impact=='1'||item.op_impact=='true')?'Yes':'No'}}
                   <!--  <v-checkbox disabled  class="pa-0 ma-0 "  :value="item.action_rii=='1'?true:false"></v-checkbox> -->
                </template>
                 <template v-slot:item.file="{ item }">

                   <v-btn :disabled="!item.file|| item.file=='undefined' || item.file=='null'" color="success" icon :href="$store.state.path2 + item.file" target="_blank"><v-icon>mdi-file</v-icon></v-btn>
                 <!--  {{(item.op_impact=='1'||item.op_impact=='true')?'Yes':'No'}} -->
                   <!--  <v-checkbox disabled  class="pa-0 ma-0 "  :value="item.action_rii=='1'?true:false"></v-checkbox> -->
                </template>


                <template v-slot:item.defect_no="{ item }">
                  <a  v-if="item.link_to_log" target="_blank" :href="$store.state.path2+item.link_to_log">{{item.defect_no}}</a>
                  <label v-else>{{item.defect_no}}</label>
                   <!--  <v-checkbox disabled  class="pa-0 ma-0 "  :value="item.action_rii=='1'?true:false"></v-checkbox> -->
                </template>
                <template v-slot:item.certificate="{ item }">
                  <v-btn  :disabled="(item.certificate == null)?true:false" color="success" small icon target="_blank" link :href="item.certificate"><v-icon>mdi-file-cad</v-icon></v-btn>
                </template>

                
              </v-data-table>
            </v-card>
         
         
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
import partsFilter from "@/components/partsFilter";
export default {
  components: {
    cabeca,
    partsFilter,
  },

  data(){
      return{
        search:null,
        dialog:false,
           headers: [
          { text: 'OPTIONS', value: 'options',class: "info white--text", width:140, },
          
          { text: 'FLEET', value: 'model',class: "info white--text", width:100,},
          { text: 'PART TYPE', value: 'part_type', class: "info white--text", width:80, },
          { text: 'ATA CHAPTER', value: 'ata_chapter' ,class: "info white--text", width:90,},
          { text: 'PART DESC', value: 'part_description',class: "info white--text", width:200, },
          { text: 'KEYWORDS', value: 'keywords' ,class: "info white--text", width:200,},
          { text: 'PART LEVEL', value: 'part_level',class: "info white--text", width:90,},
          { text: 'NHA', value: 'nha' ,class: "info white--text", width:80,},
          { text: 'QUANTITY', value: 'quantity' ,class: "info white--text", width:80,},
          { text: 'PRICE', value: 'price',class: "info white--text", width:80, },
          { text: 'PRICE EXPIRE DATE', value: 'price_expire_date',class: "info white--text", width:80, },
          
          
        ],

         
      }
  
  },

  computed:{
    desserts(){
      return this.$store.state.parts;
    }
  },

  methods:{

    percentagemFromDates(s, e){
      var start = new Date(s);
      var end = new Date(e);
      var today = new Date();

      //use Math.abs to avoid sign
      var q = today-start;
      var d = end-start;

     /*  console.info('Porcentagem', Math.round(((q/d))*100)) */
      return ((q/d))*100;
    },
      getColor (discovery,dataFinal) {
         if (this.percentagemFromDates(discovery, dataFinal) >= 100) return 'red'
        else if (this.percentagemFromDates(discovery, dataFinal) < 100 && this.percentagemFromDates(discovery, dataFinal) >= 60) return 'orange'
        else return 'green'
      },

    getDate(discovery,data ){
        let dataFinal = data;
         var date = new Date(discovery);
         let d = 0;
       if(data == "A"){
          d = 1;
       }else
       if(data == "B"){
          d = 3;
       }else
       if(data == "C"){
          d = 10;
       }else
       if(data == "D"){
          d = 120;
       }else{
         date =  new Date(data);
       }
      date.setDate(date.getDate() + d);
      var mes = date.getMonth()+1;
      dataFinal =  date.getFullYear() + "-" + ((mes.toString().length<2)?'0'+mes:mes) + '-' + ((date.getDate().toString().length<2)?'0'+date.getDate():date.getDate());

      console.log(data, dataFinal, d);
      return dataFinal||"";

      
      },


     editar(item){
        this.$store.state.editing = true;
        this.$store.state.editingItem = item;
        this.$store.state.to="/newParts";
        this.$router.push('newParts');
    },
      vedadeiro(valor){
         
          return valor;
      },
     // funcao que elimina um registo
    delete(index, item) {
      let store = this.$store;
      let axios = require("axios");
      const Swal = require("sweetalert2");

      axios
        .delete(this.$store.state.path + "parts/" + item.id.toString())
        .then(function() {
          store.state.parts.splice(index, 1);
          Swal.fire(
            "Success!",
            " data removed successfuly.",
            "success"
          );
        })
        .catch(function(error) {
          Swal.fire("Oops...", "Unable to delete. ", "error");
          console.info(error);
        });
    },
    //Este metodo é executado antes de se eliminar um registo.
    remove(index, item) {
      let dis = this;
      const Swal = require("sweetalert2");

      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          dis.delete(index, item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "This datet is is safe :)", "error");
        }
      });
    },
  },
  mounted(){
    this.$store.dispatch('permission');
      this.$store.dispatch('getParts')
      this.$store.dispatch('getAircraftInformation')
      this.$store.dispatch('getAta')
      this.$store.dispatch('defectType')
      this.$store.dispatch('getAcType');

       this.$store.state.dialog = true;
       
  }

}
</script>

<style>

</style>